import coreModule from 'app/core/core_module';
import config from 'app/core/config';

export class Matomo {
  /** @ngInject */
  constructor(private $rootScope, private $location, private $document) {}

  gaInit() {
    const _paq = ((window as any)._paq = (window as any)._paq || []);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    const u = '//' + (config as any).matomoUrl + '/';
    _paq.push(['setTrackerUrl', u + 'piwik.php']);
    _paq.push(['setSiteId', '1']);
    const d = this.$document[0],
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.defer = true;
    g.src = u + 'piwik.js';
    s.parentNode.insertBefore(g, s);
    return _paq;
  }

  init() {
    this.$rootScope.$on('$viewContentLoaded', () => {
      const ga = (window as any)._paq || this.gaInit();
      ga.push(['setCustomUrl', this.$location.path()]);
      ga.push(['trackPageView']);
    });
  }
}

/** @ngInject */
function startMatomo(matomoSrv) {
  if ((config as any).matomoUrl) {
    matomoSrv.init();
  }
}

coreModule.service('matomoSrv', Matomo).run(startMatomo);
