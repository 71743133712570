import { DetangleFilter } from './detangle_filter';
import { CalculationMethod } from '../constants';

/**
 * Filter file according to the selected folder levels.
 * Most of the time instead of this filter, global folder level
 * filter is directly applied to elasticsearch query.
 *
 * @export
 * @class FolderLevelFilter
 * @extends {DetangleFilter}
 */
export class FolderLevelFilter extends DetangleFilter {
  /**
   * Updates datahandler rows with the only files in the selected
   * folder levels.
   *
   * @returns {void}
   */
  process = (): void => {
    if (!this.shouldApplyFilter()) {
      return;
    }
    const filteredRows = this.dataListHandler
      .getRowList()
      .filter(item => this.config.folderLevel.includes(item.getFolderLevel()));
    this.dataListHandler.updateRowList(filteredRows);
  };

  /**
   * This filter is only applied to base calculations, and the data
   * is represented as folders.
   *
   * @returns {boolean}
   */
  shouldApplyFilter = (): boolean => {
    return (
      this.config.applyFolderLevel &&
      !this.config.systemOverall &&
      this.config.calculationMethod === CalculationMethod.BaseCalculation &&
      this.isFilterValid(this.config.folderLevel) &&
      !this.config.folderLevel.includes(0)
    );
  };
}
