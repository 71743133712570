// tslint:disable-next-line:import-blacklist
import moment from 'moment';
import { DetangleCalculator } from './detangle_calculator';
import { DataRowElement } from '../data_handler';
import { convertPeriod, isFilterValid } from '../utils';
import { DataFrame, toDataFrame } from '@grafana/data/src';
import { SingleValueDecorator } from '../decorators/single_value_decorator';
import { TableDecorator } from '../decorators/table_decorator';
import { calculateIncreaseRate } from './helpers';
import { FilterType } from '../constants';

const developerDataIndex = 0;
const locDataIndex = 1;
const addDataIndex = 1;
const modDataIndex = 1;
const cLocDataIndex = 2;
const delDataIndex = 3;

export enum CostCalculationType {
  DeveloperCount = 1,
  Ownership = 2,
  CostOfNewCode = 3,
  CostOfOldCode = 4,
}

export class CostCalculator extends DetangleCalculator {
  periodicDeveloperDataMap: Map<number, Map<string, any>>;
  periodList: number[];
  calculationType: CostCalculationType;

  process = () => {
    switch (this.dataHandlerList.length) {
      case 1:
        this.calculationType = CostCalculationType.DeveloperCount;
        break;
      case 2:
        this.calculationType = CostCalculationType.Ownership;
        break;
      case 3:
        this.calculationType = CostCalculationType.CostOfNewCode;
        break;
      case 4:
        this.calculationType = CostCalculationType.CostOfOldCode;
        break;
      default:
        this.calculationType = CostCalculationType.DeveloperCount;
        break;
    }
    const developerHandler = this.dataHandlerList[developerDataIndex];
    this.periodList = this.getPeriods(developerHandler.getPeriodList());
    this.periodicDeveloperDataMap = this.generateDeveloperDataMap();
    if (this.calculationType === CostCalculationType.Ownership) {
      this.calculateOwnership();
    }
    if (this.calculationType === CostCalculationType.CostOfNewCode) {
      this.calculateCostOfNewCode();
    }
    if (this.calculationType === CostCalculationType.CostOfOldCode) {
      this.calculateCostOfOldCode();
    }
    if (
      !this.config.referenceCalculation &&
      !this.config.systemOverall &&
      isFilterValid(this.config.fileInclusionFilter, FilterType.FileInclusionFilter)
    ) {
      this.applyFileInclusionFilter();
    }
  };

  toTimeSeries = (): DataFrame[] => {
    const newDataList: DataFrame[] = [];
    const pathList = {};
    this.periodicDeveloperDataMap.forEach((pathMap: Map<string, any>, tempYearMonth: number) => {
      const epochTime = moment(tempYearMonth, 'YYYYMM');
      pathMap.forEach((dataObj: any, path: string) => {
        if (!pathList[path]) {
          pathList[path] = [];
        }
        pathList[path].push([dataObj.data, epochTime]);
      });
    });
    Object.keys(pathList).map(item => {
      const dataPoints = pathList[item];
      const tempDataPointsObj = {
        datapoints: dataPoints,
        field: '@data',
        metric: 'data',
        props: { '@path': item },
        target: item,
      };
      newDataList.push(toDataFrame(tempDataPointsObj));
    });
    if (this.config.showPmData) {
      const pmList = {};
      this.periodicDeveloperDataMap.forEach((pathMap: Map<string, any>, tempYearMonth: number) => {
        const epochTime = moment(tempYearMonth, 'YYYYMM');
        pathMap.forEach((dataObj: any, path: string) => {
          if (!pmList[path]) {
            pmList[path] = [];
          }
          pmList[path].push([dataObj.pm, epochTime]);
        });
      });
      Object.keys(pmList).map(item => {
        const dataPoints = pmList[item];
        const tempDataPointsObj = {
          datapoints: dataPoints,
          field: '@pm',
          metric: 'pm',
          props: { '@path': item },
          target: item + ' pm',
        };
        newDataList.push(toDataFrame(tempDataPointsObj));
      });
    }
    return newDataList;
  };

  toTable = () => {
    const latestList = this.periodicDeveloperDataMap.get(this.config.latestTimePeriod);
    const previousList = this.periodicDeveloperDataMap.get(this.config.previousTimePeriod);
    const columns = ['path', 'data', 'increase'];
    const resultData: any[] = [];
    latestList.forEach((dataObj: any, path: string) => {
      const previousData = previousList.get(path);
      let increaseRate = 0;
      if (previousData) {
        increaseRate = calculateIncreaseRate(dataObj.data, previousData.data);
      }
      resultData.push({
        path: path,
        data: dataObj.data,
        increase: increaseRate,
        pm: dataObj.pm,
      });
    });
    if (this.calculationType !== CostCalculationType.DeveloperCount) {
      columns.push('pm');
    }
    return new TableDecorator(columns, resultData);
  };

  toSingleValue = (): SingleValueDecorator => {
    const latestList = this.periodicDeveloperDataMap.get(this.config.latestTimePeriod);
    const previousList = this.periodicDeveloperDataMap.get(this.config.previousTimePeriod);
    const latestValue = latestList.get('').data;
    const previousValue = previousList.get('').data;
    return new SingleValueDecorator(latestValue, previousValue);
  };

  private generateDeveloperDataMap = (): Map<number, Map<string, any>> => {
    const developerHandler = this.dataHandlerList[developerDataIndex];
    const developerMap = new Map<number, Map<string, any>>();
    if (this.config.applyFolderLevel) {
      developerHandler.setFoldersWithFolderLevel(this.config.folderLevel);
    }
    const rows = developerHandler.getRowList();
    for (const row of rows) {
      const path = this.getPath(row);
      const tempTimePeriod = convertPeriod(row.period, this.config.timeGranulartiy);
      if (!developerMap.has(tempTimePeriod)) {
        developerMap.set(tempTimePeriod, new Map<string, any>());
      }
      const pathMap = developerMap.get(tempTimePeriod);
      if (!pathMap.has(path)) {
        pathMap.set(path, { pm: 0, data: 0 });
      }
      pathMap.get(path).pm += row.value;
      pathMap.get(path).data += row.value;
    }
    return developerMap;
  };

  private getPath = (row: DataRowElement): string => {
    return row.path || '';
  };

  private getPeriods = (periodList: number[]): number[] => {
    let newPeriodList: number[] = [];
    for (const period of periodList) {
      const tempPeriod = convertPeriod(period, this.config.timeGranulartiy);
      newPeriodList.push(tempPeriod);
    }
    newPeriodList = [...new Set(newPeriodList)];
    return [...new Set(newPeriodList)];
  };

  private calculateOwnership = (): void => {
    const locRows = this.dataHandlerList[locDataIndex].getRowList();
    this.periodicDeveloperDataMap.forEach((pathMap: Map<string, any>, tempYearMonth: number) => {
      pathMap.forEach((dataObj: any, path: string) => {
        let tempLocValue = 0;
        const tempRow = locRows.find(x => x.path === path && x.period === tempYearMonth);
        if (tempRow) {
          tempLocValue = tempRow.value;
        }
        dataObj.ownership =
          tempLocValue === 0 || dataObj.pm === 0
            ? 0
            : Math.round((dataObj.pm / Math.max(1, Math.round((tempLocValue / 1000) * 2) / 2)) * 100) / 100;
        if (dataObj.ownership === Infinity) {
          dataObj.ownership = 0;
        }
        dataObj.data = dataObj.ownership;
      });
    });
  };

  private calculateCostOfNewCode = (): void => {
    const addRows = this.dataHandlerList[addDataIndex].getRowList();
    const cLocRows = this.dataHandlerList[cLocDataIndex].getRowList();

    this.periodicDeveloperDataMap.forEach((pathMap: Map<string, any>, tempYearMonth: number) => {
      pathMap.forEach((dataObj: any, path: string) => {
        let tempAddValue = 0;
        let tempCLOCValue = 0;

        const tempAddRow = addRows.find(x => x.path === path && x.period === tempYearMonth);
        const tempCLocRow = cLocRows.find(x => x.path === path && x.period === tempYearMonth);

        if (tempAddRow) {
          tempAddValue = tempAddRow.value;
        }
        if (tempCLocRow) {
          tempCLOCValue = tempCLocRow.value;
        }
        const percNewWork = tempAddValue === 0 || tempCLOCValue === 0 ? 0 : tempAddValue / tempCLOCValue;

        dataObj.percNewWork = percNewWork;
        dataObj.costNewCode = percNewWork * dataObj.pm;
        dataObj.data = dataObj.costNewCode;
      });
    });
  };

  private calculateCostOfOldCode = (): void => {
    const modRows = this.dataHandlerList[modDataIndex].getRowList();
    const delRows = this.dataHandlerList[delDataIndex].getRowList();
    const cLocRows = this.dataHandlerList[cLocDataIndex].getRowList();

    this.periodicDeveloperDataMap.forEach((pathMap: Map<string, any>, tempYearMonth: number) => {
      pathMap.forEach((dataObj: any, path: string) => {
        let tempModValue = 0;
        let tempDelValue = 0;
        let tempCLOCValue = 0;

        const tempModRow = modRows.find(x => x.path === path && x.period === tempYearMonth);
        const tempDelRow = delRows.find(x => x.path === path && x.period === tempYearMonth);
        const tempCLocRow = cLocRows.find(x => x.path === path && x.period === tempYearMonth);

        if (tempModRow) {
          tempModValue = tempModRow.value;
        }
        if (tempDelRow) {
          tempDelValue = tempDelRow.value;
        }
        if (tempCLocRow) {
          tempCLOCValue = tempCLocRow.value;
        }
        const oldTemp = tempModValue * 2 + tempDelValue;
        const percOldWork = oldTemp === 0 || tempCLOCValue === 0 ? 0 : oldTemp / tempCLOCValue;

        dataObj.percOldWork = percOldWork;
        dataObj.costOldCode = percOldWork * dataObj.pm;
        dataObj.data = dataObj.costOldCode;
      });
    });
  };

  private applyFileInclusionFilter = () => {
    const regexChecker = new RegExp(this.config.fileInclusionFilter);
    this.periodicDeveloperDataMap.forEach((pathMap: Map<string, any>, tempYearMonth: number) => {
      pathMap.forEach((dataObj: any, path: string) => {
        if (!regexChecker.test(path)) {
          pathMap.delete(path);
        }
      });
    });
  };
}
