import _ from 'lodash';
import { DbColumn } from './constants';
import { nthIndex, groupByArray, nthIndexAlternative, getFolder } from './utils';

export enum DataListRefType {
  Issue = 'Issue',
  Author = 'Author',
  Interval = 'Interval',
  Days = 'Days',
}
/**
 * Handles table data list type from elasticsearch and make it easier
 * to access attributes and aggregate according to the desired predicates.
 *
 * @export
 * @class DataHandler
 */
export class DataHandler {
  dataList: any;
  pathColumnIndex: number;
  parentDirectoryColumnIndex: number;
  refColumnIndex: number;
  yearMonthColumnIndex: number;
  issueTitleColumnIndex: number;
  issueTypeColumnIndex: number;
  valueColumnIndex: number;
  refType: DataListRefType;
  rowList: DataRowElement[];
  periodList: number[];

  constructor(dataList: any) {
    this.dataList = dataList;
    this.init();
  }

  /**
   * Gets columns from the data list.
   *
   * @returns {any[]}
   */
  getColumnList = (): any[] => {
    return this.dataList.columns;
  };

  /**
   * Gets untouched row list from data list.
   *
   * @returns {any[]}
   */
  getLegacyRowList = (): any[] => {
    return this.dataList.rows;
  };

  /**
   * Gets formatted rows.
   *
   * @returns {DataRowElement[]}
   */
  getRowList = (): DataRowElement[] => {
    return this.rowList;
  };

  /**
   * Updates the row list.
   *
   * @param {DataRowElement[]} newRows
   */
  updateRowList = (newRows: DataRowElement[]): void => {
    this.rowList = newRows;
  };

  /**
   * Updates the row list and updates the raw data from the query.
   *
   * @param {DataRowElement[]} newRows
   */
  updateRowListHard = (newRows: DataRowElement[]): void => {
    this.rowList = newRows;
    this.dataList.rows = newRows.map(item => item.rawData);
  };

  /**
   * Sets the time period list from related attribute.
   *
   */
  setPeriodList = (): void => {
    this.periodList = _.uniq(this.getRowList().map(item => item.period)).sort();
  };

  /**
   * Gets period list
   *
   * @returns {number[]}
   */
  getPeriodList = (): number[] => {
    return this.periodList;
  };

  /**
   * Gets grouped row list according to the path
   * or reference attribute.
   *
   * @param {boolean} [reference=false]
   * @returns {*}
   */
  getGroupedRowList = (reference = false): any => {
    const keyPredicate = reference ? x => x.ref : x => x.path;
    return groupByArray(this.getRowList(), keyPredicate);
  };

  /**
   * Gets current data as original data list which is like
   * returned from elasticsearch.
   *
   * @returns {*}
   */
  getAsRawData = (): any => {
    const originalDataList = _.clone(this.dataList);
    originalDataList.rows = this.getRowList().map(item => item.rawData);
    return originalDataList;
  };

  /**
   * Changes the path of the rows to their parent folders.
   * While doing this it gets folder level array which is set
   * from global filters.
   *
   * It gets multiple folder levels and how parent folder is decided
   * differs according to if the path of file fits on maximum value of
   * folder levels or not.
   *
   * Example for folderLevelArray: [3, 5]
   *
   * if a file is under folder level 4, we don't consider this file. However,
   * if another file is under folder level 6, this file is considered and parent
   * directory is set to folder that is under folder level 5.
   *
   * This method always doesn't consider file above the minimum folder level in
   * the folder level array.
   *
   * @param {number[]} folderLevelArray
   * @param {boolean} [removeLowerlevels=true]
   */
  setFoldersWithFolderLevel = (folderLevelArray: number[], removeLowerlevels = true): void => {
    this.rowList.forEach(row => {
      let currentFolderLevel = row.getFolderLevel();
      row.path = row.getPath();
      while (currentFolderLevel > 0) {
        if (folderLevelArray.includes(currentFolderLevel)) {
          row.path = row.getPathWithLevel(currentFolderLevel);
          break;
        }
        currentFolderLevel--;
      }
    });
    if (removeLowerlevels) {
      const filteredRows = this.rowList.filter(item => folderLevelArray.includes(item.getFolderLevel()));
      this.rowList = filteredRows;
    }
  };

  /**
   * Gets the aggregated row data for the selected time period.
   * if periods to consider is set to some value higher than 0, number of
   * previous desired periods are included in the aggregation.
   *
   * @param {number} timePeriod
   * @param {number} [periodsToConsider=0]
   * @returns {DataRowElement[]}
   */
  getAggregatedRowListWithTimePeriod = (timePeriod: number, periodsToConsider = 0): DataRowElement[] => {
    if (periodsToConsider > 0) {
      const currentTimePeriodIndex = this.periodList.findIndex(item => item === timePeriod);
      const startingPeriodIndex =
        currentTimePeriodIndex > periodsToConsider ? currentTimePeriodIndex - periodsToConsider : 0;
      const startingPeriod = this.periodList[startingPeriodIndex];
      return this.getAggregatedRowList(item => {
        return item.period >= startingPeriod && item.period <= timePeriod;
      });
    }
    return this.getAggregatedRowList(item => {
      return item.period === timePeriod;
    });
  };

  /**
   * Gets aggregated row list according to the provided predicate function.
   *
   * @param {*} predicate
   * @returns {DataRowElement[]}
   */
  getAggregatedRowList = (predicate: any): DataRowElement[] => {
    const filteredRows = this.rowList.filter(item => predicate(item));
    const helper = {};
    const aggregatedRows = filteredRows.reduce((r, o) => {
      let key = o.path;
      if (o.ref) {
        key += o.ref;
      }
      if (!helper[key]) {
        helper[key] = _.cloneDeep(o); // create a copy of o
        r.push(helper[key]);
      } else {
        helper[key].value += o.value;
      }
      return r;
    }, []);
    return aggregatedRows;
  };

  /**
   * Gets the clone of the current data handler.
   * dataList variable of the object is not affected by any calculation.
   * As other clonning options do not work as intended this is the currently
   * the best way to get pristine version of the current dataHandle object.
   *
   * @returns {DataHandler}
   */
  clone = (): DataHandler => {
    return new DataHandler(this.dataList);
  };

  /**
   * Initializes the data handler
   *
   */
  private init = (): void => {
    this.setPathColumnIndex();
    this.setParentDirectoryColumnIndex();
    this.setRefColumnIndex();
    this.setYearMonthIndex();
    this.setValueColumnIndex();
    this.setRowList();
    this.setPeriodList();
    this.setIssueTitleColumnIndex();
    this.setIssueTypeColumnIndex();
  };

  /**
   * Set the object's row list from the untouched
   * row list to DataRowElement array.
   *
   */
  private setRowList = (): void => {
    this.rowList = this.getLegacyRowList().map(item => new DataRowElement(this, item));
  };

  /**
   * Gets the index of the given column name in the column list array.
   *
   * @param {*} columnName
   * @returns {number}
   */
  private getColumnIndex = (columnName: any): number => {
    return _.findIndex(this.getColumnList(), { text: columnName });
  };

  /**
   * Sets path column index.
   *
   */
  private setPathColumnIndex = (): void => {
    this.pathColumnIndex = this.getColumnIndex(DbColumn.PathColumn);
  };

  /**
   * Sets parentDirectory column index.
   *
   */
  private setParentDirectoryColumnIndex = (): void => {
    this.parentDirectoryColumnIndex = this.getColumnIndex(DbColumn.ParentDirectoryColumn);
  };

  /**
   * Sets yearMonth column index.
   *
   */
  private setYearMonthIndex = (): void => {
    this.yearMonthColumnIndex = this.getColumnIndex(DbColumn.YearMonthColumn);
  };

  /**
   * Sets reference column index. While doing that, the method sets also
   * what kind of reference type it is.
   *
   */
  private setRefColumnIndex = (): void => {
    let columnIndex = this.getColumnIndex(DbColumn.IssueIdColumn);
    this.refType = DataListRefType.Issue;
    if (columnIndex === -1) {
      columnIndex = this.getColumnIndex(DbColumn.AuthorColumn);
      this.refType = DataListRefType.Author;
    }
    if (columnIndex === -1) {
      columnIndex = this.getColumnIndex(DbColumn.IntervalColumn);
      this.refType = DataListRefType.Interval;
    }
    if (columnIndex === -1) {
      columnIndex = this.getColumnIndex(DbColumn.DaysColumn);
      this.refType = DataListRefType.Days;
    }
    this.refColumnIndex = columnIndex;
  };

  /**
   * Sets value column index. Grafana sets value columns always the last
   * in the column array.
   *
   */
  private setValueColumnIndex = (): void => {
    this.valueColumnIndex = this.getColumnList().length - 1;
  };

  /**
   * Sets issue title column index.
   *
   */
  private setIssueTitleColumnIndex = (): void => {
    this.issueTitleColumnIndex = this.getColumnIndex(DbColumn.TitleColumn);
  };

  /**
   * Sets issue type column index.
   *
   */
  private setIssueTypeColumnIndex = (): void => {
    this.issueTypeColumnIndex = this.getColumnIndex(DbColumn.IssueTypeColumn);
  };
}
/**
 * Formatted class representation of the data list rows.
 *
 * @export
 * @class DataRowElement
 */
export class DataRowElement {
  path: string;
  ref: string;
  issueTitle: string;
  period: number;
  value: number;
  rawData: any[];

  /**
   * Creates an instance of DataRowElement.
   *
   * @param {DataHandler} dataListHandler
   * @param {any[]} row
   * @memberof DataRowElement
   */
  constructor(dataListHandler: DataHandler, row: any[]) {
    this.rawData = row;
    if (dataListHandler.pathColumnIndex > -1) {
      this.path = row[dataListHandler.pathColumnIndex];
    }
    if (dataListHandler.parentDirectoryColumnIndex > -1 && dataListHandler.pathColumnIndex === -1) {
      this.path = row[dataListHandler.parentDirectoryColumnIndex];
    }
    if (dataListHandler.refColumnIndex > -1) {
      this.ref = row[dataListHandler.refColumnIndex];
    }
    if (dataListHandler.yearMonthColumnIndex > -1) {
      this.period = row[dataListHandler.yearMonthColumnIndex];
    }
    if (dataListHandler.issueTitleColumnIndex > -1) {
      this.issueTitle = row[dataListHandler.issueTitleColumnIndex];
    }
    this.value = row[dataListHandler.valueColumnIndex];
  }

  /**
   * Gets the current folder level of the path.
   *
   * @returns {number}
   */
  getFolderLevel = (): number => {
    return (this.path.match(/\//g) || []).length;
  };

  /**
   * Gets the parent directory of the file.
   *
   * @returns {string}
   */
  getPath = (): string => {
    return getFolder(this.path);
  };

  /**
   * Gets the parent directory of the file on a given folder level.
   *
   * @param {number} folderLevel
   * @returns {string}
   */
  getPathWithLevel = (folderLevel: number): string => {
    return this.path.substring(0, nthIndex(this.path, '/', folderLevel) + 1);
  };

  /**
   * Gets the parent directory of the file on a given folder level.
   * This is an alternative version of getting the parent directory because
   * getPathWithLevel doesn't return any value if there is no directory available
   * for the given folder level. This method checks if a directory available on
   * the given folder level and if it is not it returns the current parent directory.
   *
   * @param {number} folderLevel
   * @returns {string}
   */
  getPathWithLevelAlternative = (folderLevel: number): string => {
    return this.path.substring(0, nthIndexAlternative(this.path, '/', folderLevel) + 1);
  };
}
