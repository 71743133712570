import { DetangleCalculator } from './detangle_calculator';

export class BaseCalculator extends DetangleCalculator {
  process = (): void => {};

  toTimeSeries: () => any;

  toTable = (): any => {
    const originalDataList = [];
    this.dataHandlerList.forEach(dataHandler => {
      originalDataList.push(dataHandler.getAsRawData());
    });
    return originalDataList;
  };

  toSingleValue: () => any;
}
