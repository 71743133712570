import { FilterType, DbColumn, TimeGranularity } from './constants';
import _ from 'lodash';

export const isInt = value => {
  return (
    !isNaN(value) &&
    (x => {
      return (x | 0) === x;
    })(parseFloat(value))
  );
};

export const nthIndex = (str, pat, n) => {
  const L = str.length;
  let i = -1;
  while (n-- && i++ < L) {
    i = str.indexOf(pat, i);
    if (i < 0) {
      break;
    }
  }
  return i;
};

export const nthIndexAlternative = (str, pat, n) => {
  const L = str.length;
  let i = -1;
  while (n-- && i++ < L) {
    const oldIndex = i - 1;
    i = str.indexOf(pat, i);
    if (i < 0) {
      return oldIndex;
    }
  }
  return i;
};

export const trimConfParameter = parameter => {
  return parameter ? parameter.trim() : '';
};

export const groupBy = (xs, keyPredicate) => {
  return xs.reduce((rv, x) => {
    (rv[keyPredicate(x)] = rv[keyPredicate(x)] || []).push(x);
    return rv;
  }, {});
};

export const groupByArray = (dataToBeGrouped, keyPredicate) => {
  const groupObjectdata = groupBy(dataToBeGrouped, keyPredicate);
  return Object.keys(groupObjectdata).map(item => {
    return { item, connections: groupObjectdata[item] };
  });
};

/**
 * Checks if the expression that is set from
 * templating variables (global filters) is valid.
 * User manually writes "-" to state that this filter should not be applied.
 * In some cases templating service returns empty data or variable name.
 * For these cases the expression is not valid as well.
 *
 * @param {*} filterExpression
 * @param {FilterType} filterType
 * @returns
 */
export const isFilterValid = (filterExpression: any, filterType: FilterType) => {
  return (
    filterExpression !== undefined &&
    filterExpression !== '' &&
    filterExpression !== '-' &&
    filterExpression !== filterType
  );
};

export const increaseAsPercentage = (latestValue: number, previousValue: number): number => {
  return Math.floor((latestValue / previousValue) * 100) - 100;
};

export const averageCalculator = dataList => {
  for (let i = 1; i < dataList.length; i++) {
    dataList[0].datapoints[0][0] += dataList[i].datapoints[0][0];
  }
  dataList[0].datapoints[0][0] = dataList[0].datapoints[0][0] / dataList.length;
  dataList.splice(1);
  return dataList;
};

export const issueIdTitleChecker = (dataList, regex) => {
  const tempColumns = dataList.columns;
  const tempIssueTitleIndex: number = _.findIndex(tempColumns, { text: DbColumn.TitleColumn });
  const tempIssueIdIndex: number = _.findIndex(tempColumns, { text: DbColumn.IssueIdColumn });
  const tempRows = dataList.rows;
  const groupedIssueTitles = groupBy(tempRows, tempIssueIdIndex);
  const issueTitleRows = Object.keys(groupedIssueTitles).reduce((previous, current) => {
    previous[current] = groupedIssueTitles[current][0][tempIssueTitleIndex];
    return previous;
  }, {});

  const acceptedIssues = {};
  const issueTitleRegexChecker = new RegExp(regex);
  Object.keys(issueTitleRows).map(issueId => {
    if (issueTitleRegexChecker.test(issueId + issueTitleRows[issueId])) {
      acceptedIssues[issueId] = true;
    }
  });
  return acceptedIssues;
};

export const dataListAggregator = (dataList, predicate, columnName = DbColumn.YearMonthColumn) => {
  const tempColumns = dataList.columns;
  const columnIndex = _.findIndex(tempColumns, { text: columnName });
  dataList.rows = dataList.rows.filter(item => predicate(item, columnIndex));
  dataList.columns.splice(columnIndex, 1);
  dataList.rows = dataList.rows.map(item => {
    item.splice(columnIndex, 1);
    return item;
  });
  const valueIndex = dataList.columns.length - 1;
  const numberOfDataColumns = dataList.columns.length - 2;
  const helper = {};

  dataList.rows = dataList.rows.reduce((r, o) => {
    let key = o[0];
    for (let i = 1; i <= numberOfDataColumns; i++) {
      key = key + '-' + o[i];
    }
    if (!helper[key]) {
      helper[key] = Object.assign({}, o); // create a copy of o
      r.push(helper[key]);
    } else {
      helper[key][valueIndex] += o[valueIndex];
    }

    return r;
  }, []);
  return dataList;
};

export const convertPeriod = (givenPeriod: number, timeGranularity: TimeGranularity): number => {
  if (timeGranularity === TimeGranularity.Year) {
    return parseInt(givenPeriod.toString().slice(0, -2) + '07', 10);
  } else {
    return givenPeriod;
  }
};

export const getFolder = (path: string): string => {
  const tempSlashIndex = path.lastIndexOf('/');
  if (tempSlashIndex === -1) {
    return path;
  }
  return path.substring(0, tempSlashIndex + 1);
};
