import { DetangleCalculator } from './detangle_calculator';
import { SingleValueDecorator } from '../decorators/single_value_decorator';

/**
 * Calculation class to to find trends of the simple data.
 *
 * @export
 * @class TrendCalculator
 * @extends {DetangleCalculator}
 */
export class TrendCalculator extends DetangleCalculator {
  process = (): void => {};

  toTimeSeries: () => any;

  toTable: () => any;

  /**
   * Gets the sum of both dataHandler's data and returns SingleValueDecorator
   *
   * @returns {*}
   */
  toSingleValue = (): any => {
    const latestValueList = this.dataHandlerList[0].getRowList();
    const latestValue = latestValueList.reduce((sum, current) => sum + current.value, 0);
    const previousValueList = this.dataHandlerList[1].getRowList();
    const previousValue = previousValueList.reduce((sum, current) => sum + current.value, 0);
    return new SingleValueDecorator(latestValue, previousValue);
  };
}
