import _ from 'lodash';
import { colors, getColorFromHexRgbOrName } from '@grafana/ui';
import { TimeRange, FieldCache, FieldType, Field, DataFrame } from '@grafana/data';
import TimeSeries from 'app/core/time_series2';
import config from 'app/core/config';

type Options = {
  dataList: DataFrame[];
  range?: TimeRange;
};

export class DataProcessor {
  constructor(private panel: any) {}

  getSeriesList(options: Options): TimeSeries[] {
    const list: TimeSeries[] = [];
    let dataList = options.dataList;
    const range = options.range;

    if (!dataList || !dataList.length) {
      return list;
    }
    // Subtract dataList
    if (this.panel.detangle && this.panel.detangle.subtraction && dataList.length > 1) {
      const newDataList = dataList[0];
      for (let i = 0; i < newDataList.rows.length; i++) {
        newDataList.rows[i][0] = dataList[0].rows[i][0] - dataList[1].rows[i][0];
      }
      dataList = [newDataList];
    }

    if (this.panel.detangle && this.panel.detangle.ratio && dataList.length > 1) {
      const midPoint: number = Math.floor(dataList.length / 2);
      const newDataList = [];
      for (let i = 0; i < midPoint; i++) {
        newDataList.push(dataList[i]);
        for (let j = 0; j < dataList[i].rows.length; j++) {
          if (dataList[i + midPoint].rows[j][0] === 0) {
            newDataList[i].rows[j][0] = 0;
          } else {
            newDataList[i].rows[j][0] = newDataList[i].rows[j][0] / dataList[i + midPoint].rows[j][0];
          }
          newDataList[i].field = 'Ratio';
        }
      }
      dataList = newDataList;
    }
    if (this.panel.detangle && this.panel.detangle.aggregate) {
      for (let i = 0; i < dataList.length; i++) {
        for (let j = 1; j < dataList[i].rows.length; j++) {
          if (dataList[i].rows[j][0] === 0 && isRemainingDataZero(dataList[i].rows, j)) {
            continue;
          }
          dataList[i].rows[j][0] += dataList[i].rows[j - 1][0];
        }
      }
    }

    function isRemainingDataZero(datapoints, currentIndex) {
      let isRemainingZero = true;
      for (let i = currentIndex; i < datapoints.length; i++) {
        if (datapoints[i][0] !== 0) {
          isRemainingZero = false;
        }
      }
      return isRemainingZero;
    }

    const isSingleField = dataList.length === 1;
    for (const series of dataList) {
      const { fields } = series;
      const cache = new FieldCache(fields);
      const time = cache.getFirstFieldOfType(FieldType.time);

      if (!time) {
        continue;
      }

      const seriesName = series.name ? series.name : series.refId;
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].type !== FieldType.number) {
          continue;
        }

        const field = fields[i];
        let name = field.title;

        if (!field.title) {
          name = field.name;
        }

        if (seriesName && dataList.length > 0 && name !== seriesName) {
          name = seriesName + ' ' + name;
        }

        const datapoints = [];
        for (const row of series.rows) {
          datapoints.push([row[i], row[time.index]]);
        }

        list.push(this.toTimeSeries(field, name, datapoints, list.length, range, isSingleField));
      }
    }

    // Merge all the rows if we want to show a histogram
    if (this.panel.xaxis.mode === 'histogram' && !this.panel.stack && list.length > 1) {
      const first = list[0];
      first.alias = first.aliasEscaped = 'Count';
      for (let i = 1; i < list.length; i++) {
        first.datapoints = first.datapoints.concat(list[i].datapoints);
      }
      return [first];
    }
    return list;
  }

  strToColor(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  private toTimeSeries(
    field: Field,
    alias: string,
    datapoints: any[][],
    index: number,
    range?: TimeRange,
    isSingleField?: boolean
  ) {
    const colorIndex = index % colors.length;
    let color = this.panel.aliasColors[alias] || colors[colorIndex];
    if (isSingleField) {
      color = config.bootData.user.lightTheme ? '#52545c' : '#d8d9da';
    }
    if (this.panel.rainbowColors) {
      color = this.strToColor(alias);
    }

    const series = new TimeSeries({
      datapoints: datapoints || [],
      alias: alias,
      color: getColorFromHexRgbOrName(color, config.theme.type),
      unit: field.unit,
    });

    if (datapoints && datapoints.length > 0 && range) {
      const last = datapoints[datapoints.length - 1][1];
      const from = range.from;

      if (last - from.valueOf() < -10000) {
        series.isOutsideRange = true;
      }
    }
    return series;
  }

  setPanelDefaultsForNewXAxisMode() {
    switch (this.panel.xaxis.mode) {
      case 'time': {
        this.panel.bars = false;
        this.panel.lines = true;
        this.panel.points = false;
        this.panel.legend.show = true;
        this.panel.tooltip.shared = true;
        this.panel.xaxis.values = [];
        break;
      }
      case 'series': {
        this.panel.bars = true;
        this.panel.lines = false;
        this.panel.points = false;
        this.panel.stack = false;
        this.panel.legend.show = false;
        this.panel.tooltip.shared = false;
        this.panel.xaxis.values = ['total'];
        break;
      }
      case 'histogram': {
        this.panel.bars = true;
        this.panel.lines = false;
        this.panel.points = false;
        this.panel.stack = false;
        this.panel.legend.show = false;
        this.panel.tooltip.shared = false;
        break;
      }
    }
  }

  validateXAxisSeriesValue() {
    switch (this.panel.xaxis.mode) {
      case 'series': {
        if (this.panel.xaxis.values.length === 0) {
          this.panel.xaxis.values = ['total'];
          return;
        }

        const validOptions = this.getXAxisValueOptions({});
        const found: any = _.find(validOptions, { value: this.panel.xaxis.values[0] });
        if (!found) {
          this.panel.xaxis.values = ['total'];
        }
        return;
      }
    }
  }

  getXAxisValueOptions(options: any) {
    switch (this.panel.xaxis.mode) {
      case 'series': {
        return [
          { text: 'Avg', value: 'avg' },
          { text: 'Min', value: 'min' },
          { text: 'Max', value: 'max' },
          { text: 'Total', value: 'total' },
          { text: 'Count', value: 'count' },
        ];
      }
    }

    return [];
  }

  pluckDeep(obj: any, property: string) {
    const propertyParts = property.split('.');
    let value = obj;
    for (let i = 0; i < propertyParts.length; ++i) {
      if (value[propertyParts[i]]) {
        value = value[propertyParts[i]];
      } else {
        return undefined;
      }
    }
    return value;
  }
}
