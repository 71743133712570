// tslint:disable-next-line:import-blacklist
import moment from 'moment';
import { DataFrame, toDataFrame } from '@grafana/data/src';
import { DetangleCalculator } from './detangle_calculator';
import { SingleValueDecorator } from '../decorators/single_value_decorator';
import { DataHandler } from '../data_handler';
import { TableDecorator } from '../decorators/table_decorator';
import { getDeeperFolderLevel } from './helpers';
import { isFilterValid } from '../utils';
import { FilterType } from '../constants';

const developerDataIndex = 0;
const locDataIndex = 1;

/**
 * Calculation class to to find knowledge islands or balances.
 *
 * @export
 * @class KnowledgeCalculator
 * @extends {DetangleCalculator}
 */
export class KnowledgeCalculator extends DetangleCalculator {
  periodDataMap: Map<number, Map<string, any>>;
  periodList: number[];
  hasLocData: boolean;

  process = (): void => {
    const numberOfDataList = this.dataHandlerList.length;
    const developerDataHandler = this.dataHandlerList[developerDataIndex];
    this.periodList = developerDataHandler.getPeriodList();
    this.hasLocData = numberOfDataList > 1;
    this.periodDataMap = new Map<number, any>();

    for (const period of this.periodList) {
      this.periodDataMap.set(period, this.getKnowledgeDistributionForPeriod(period));
    }
    if (this.hasLocData) {
      this.setLocData();
    }
  };

  toTimeSeries = (): DataFrame[] => {
    const newDataList: DataFrame[] = [];
    const datapoints = [];
    this.periodList.forEach(period => {
      const tempDataMap = this.periodDataMap.get(period);
      const epochTime = moment(period, 'YYYYMM');
      datapoints.push([this.getTotalValue(tempDataMap), epochTime]);
    });
    const tempDataPointsObj = {
      datapoints: datapoints,
      field: '@KnowledgeDistribution',
      metric: 'KnowledgeDistribution',
      props: { '@path': '' },
      target: '',
    };
    newDataList.push(toDataFrame(tempDataPointsObj));
    return newDataList;
  };

  toTable = (): TableDecorator => {
    const latestList = this.periodDataMap.get(this.config.latestTimePeriod);
    let folderData = this.calculateDataForFolders(latestList);
    const columns = [
      'path',
      'knowledgeDistFileCount',
      'knowledgeDistLoc',
      'fileCount',
      'knowledgeDistRatio',
      'locRatio',
      'loc',
    ];
    if (
      !this.config.referenceCalculation &&
      !this.config.systemOverall &&
      isFilterValid(this.config.fileInclusionFilter, FilterType.FileInclusionFilter)
    ) {
      folderData = this.applyFileInclusionFilter(folderData);
    }
    return new TableDecorator(columns, folderData);
  };

  /**
   * Gets the sum of both dataHandler's data and returns SingleValueDecorator
   *
   * @returns {*}
   */
  toSingleValue = (): any => {
    const latestValue = this.getTotalValue(this.periodDataMap.get(this.config.latestTimePeriod));
    const previousValue = this.getTotalValue(this.periodDataMap.get(this.config.previousTimePeriod));
    return new SingleValueDecorator(latestValue, previousValue);
  };

  private getKnowledgeDistributionForPeriod = (currentPeriod: number) => {
    const dataMap = new Map<string, any>();

    const debtDataHandler: DataHandler = this.dataHandlerList[developerDataIndex].clone();
    debtDataHandler.updateRowList(
      debtDataHandler.getAggregatedRowListWithTimePeriod(currentPeriod, this.config.periodsToConsider)
    );
    const groupedPathArray = debtDataHandler.getGroupedRowList();
    groupedPathArray.map((x: { connections: any[]; item: string }) => {
      const dataAttributeObj = {
        numberOfDevelopers: x.connections.length,
        mainData: this.config.distinctDeveloperCount.includes(x.connections.length),
        loc: 0,
      };
      dataMap.set(x.item, dataAttributeObj);
    });
    return dataMap;
  };

  private setLocData = (): void => {
    const locMainDataHandler = this.dataHandlerList[locDataIndex];
    if (this.config.applyFolderLevel) {
      locMainDataHandler.setFoldersWithFolderLevel(this.config.folderLevel);
    }
    for (const period of this.periodList) {
      const locDataHandler: DataHandler = locMainDataHandler.clone();
      locDataHandler.updateRowList(locDataHandler.getAggregatedRowListWithTimePeriod(period));
      const tempDataMap = this.periodDataMap.get(period);
      locDataHandler.getRowList().forEach(row => {
        if (!tempDataMap.has(row.path)) {
          return;
        }
        const tempDataAttributes = tempDataMap.get(row.path);
        tempDataAttributes.loc = row.value;
        tempDataMap.set(row.path, tempDataAttributes);
      });
    }
  };

  private getTotalValue = (dataMap: Map<string, any>): number => {
    let mainTotal = 0;
    let grandTotal = 0;
    dataMap.forEach((dataAttr: any) => {
      const effectiveData = this.hasLocData ? dataAttr.loc : 1;
      if (dataAttr.mainData) {
        mainTotal += effectiveData;
      }
      grandTotal += effectiveData;
    });
    return mainTotal / Math.max(1, grandTotal);
  };

  private calculateDataForFolders(dataMap: Map<string, any>): any[] {
    const newMap = new Map<string, any>();

    dataMap.forEach((dataAttr: any, key: string) => {
      const pathDeepLevel = getDeeperFolderLevel(key, this.config.folderLevel);
      if (pathDeepLevel === '') {
        return;
      }
      let newAttr: any;
      if (newMap.has(pathDeepLevel)) {
        newAttr = newMap.get(pathDeepLevel);
      } else {
        newAttr = {
          knowledgeDistFileCount: 0,
          fileCount: 0,
          knowledgeDistLoc: 0,
          loc: 0,
          knowledgeDistRatio: 0,
          locRatio: 0,
        };
      }
      newAttr.knowledgeDistFileCount += dataAttr.mainData ? 1 : 0;
      newAttr.fileCount += 1;
      newAttr.knowledgeDistLoc += dataAttr.mainData ? dataAttr.loc : 0;
      newAttr.loc += dataAttr.loc;
      newMap.set(pathDeepLevel, newAttr);
    });
    const returnList = [];

    newMap.forEach((dataAttr: any, key: string) => {
      const newReturnRow = {
        path: key,
        knowledgeDistFileCount: dataAttr.knowledgeDistFileCount,
        fileCount: dataAttr.fileCount,
        knowledgeDistLoc: dataAttr.knowledgeDistLoc,
        loc: dataAttr.loc,
        knowledgeDistRatio: dataAttr.knowledgeDistFileCount / Math.max(1, dataAttr.fileCount),
        locRatio: dataAttr.knowledgeDistLoc / Math.max(1, dataAttr.loc),
      };
      returnList.push(newReturnRow);
    });
    return returnList;
  }

  private applyFileInclusionFilter = (folderData: any[]): any[] => {
    const regexChecker = new RegExp(this.config.fileInclusionFilter);
    return folderData.filter(x => x.path === '' || regexChecker.test(x.path));
  };
}
