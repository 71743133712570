import { MetricRangeFilter } from './metric_range_filter';
import { FilterType } from '../constants';
import { FileExclusionFilter } from './file_exclusion_filter';
import { FileInclusionFilter } from './file_inclusion_filter';
import { FolderLevelFilter } from './folder_level_filter';
import { DetangleFilter } from './detangle_filter';
import { DetangleConfig } from '../detangle_config';
import { DataHandler } from '../data_handler';

/**
 * Abstract factory method that returns filter class object
 * according to given filter type.
 *
 * @param {FilterType} filterType
 * @param {DataHandler} dataList
 * @param {DetangleConfig} config
 * @returns {DetangleFilter}
 */
export const createFilter = (filterType: FilterType, dataList: DataHandler, config: DetangleConfig): DetangleFilter => {
  switch (filterType) {
    case FilterType.FolderLevelFilter:
      return new FolderLevelFilter(filterType, dataList, config);
    case FilterType.FileInclusionFilter:
      return new FileInclusionFilter(filterType, dataList, config);
    case FilterType.FileExclusionFilter:
      return new FileExclusionFilter(filterType, dataList, config);
    case FilterType.MetricRangeFilter:
      return new MetricRangeFilter(filterType, dataList, config);
    default:
      throw new Error('NotImplemented');
  }
};
