import { TimeRange, toUtc, AbsoluteTimeRange } from '@grafana/data';
// tslint:disable-next-line:import-blacklist
import { DurationUnit } from '@grafana/data';

export const getShiftedTimeRange = (direction: number, origRange: TimeRange): AbsoluteTimeRange => {
  const range = {
    from: toUtc(origRange.from),
    to: toUtc(origRange.to),
  };

  const timespan = (range.to.valueOf() - range.from.valueOf()) / 2;
  let to: number, from: number;

  if (direction === -1) {
    to = range.to.valueOf() - timespan;
    from = range.from.valueOf() - timespan;
  } else if (direction === 1) {
    to = range.to.valueOf() + timespan;
    from = range.from.valueOf() + timespan;
    if (to > Date.now() && range.to.valueOf() < Date.now()) {
      to = Date.now();
      from = range.from.valueOf();
    }
  } else {
    to = range.to.valueOf();
    from = range.from.valueOf();
  }

  return { from, to };
};

export const shiftPeriod = (direction: number, period: DurationUnit, origRange: TimeRange): AbsoluteTimeRange => {
  console.log(period);
  const range = {
    from: toUtc(origRange.from.add(direction, period)),
    to: toUtc(origRange.to.add(direction, period)),
  };
  console.log(period);
  console.log(range);
  let to: number, from: number;
  from = range.from.valueOf();
  to = range.to.valueOf();
  console.log(from);
  if (to > Date.now() && range.to.valueOf() < Date.now()) {
    to = Date.now();
    from = range.from.valueOf();
  }

  return { from, to };
};

export const getZoomedTimeRange = (range: TimeRange, factor: number): AbsoluteTimeRange => {
  const timespan = range.to.valueOf() - range.from.valueOf();
  const center = range.to.valueOf() - timespan / 2;

  const to = center + (timespan * factor) / 2;
  const from = center - (timespan * factor) / 2;

  return { from, to };
};
