// tslint:disable-next-line:import-blacklist
import moment from 'moment';
import _ from 'lodash';
import { DataFrame, toDataFrame } from '@grafana/data/src';
import { DetangleCalculator } from './detangle_calculator';
import { DataHandler } from '../data_handler';
import { DebtCalculator } from './debt_calculator';
import { debtNormalizer, calculateIncreaseRate, getAsRevisionData } from './helpers';
import { SingleValueDecorator } from '../decorators/single_value_decorator';
import { TableDecorator } from '../decorators/table_decorator';

const debtDataIndex = 0;
const issueTitleDataIndex = 1;
const normalizerDataIndex = (length: number) => length - 1;

export class PeriodicDebtCalculator extends DetangleCalculator {
  primaryDataMap: Map<string, any>;
  periodList: number[];
  periodDataMap: Map<number, any>;
  periodNormalizerDataMap: Map<number, any>;

  process = (): void => {
    const debtDataHandler = this.dataHandlerList[debtDataIndex];
    this.periodList = debtDataHandler.getPeriodList();
    this.periodDataMap = new Map<number, any>();
    this.periodNormalizerDataMap = new Map<number, any>();
  };

  toTimeSeries = (): DataFrame[] => {
    const newDataList: DataFrame[] = [];
    this.periodList.forEach(period => {
      this.periodDataMap.set(period, this.getDebtForPeriod(period));
    });
    const keyValueHash = {};
    if (this.config.isNormalized) {
      this.periodList.forEach(period => {
        this.periodNormalizerDataMap.set(period, this.getNormalizationDataForPeriod(period));
      });
    }
    this.periodList.forEach(period => {
      const epochTime = moment(period, 'YYYYMM');
      let debtList = this.periodDataMap.get(period);
      if (!this.config.applyFolderLevel) {
        debtList = this.getTotalDebt(debtList);
      }
      if (this.config.isNormalized) {
        let normalizerData = this.periodNormalizerDataMap.get(period);
        if (this.config.systemOverall) {
          normalizerData = getAsRevisionData(normalizerData);
        }
        const normalizedList = debtNormalizer(
          debtList,
          normalizerData,
          !this.config.systemOverall && this.config.referenceCalculation
        );
        normalizedList.forEach(normalizedItem => {
          if (!keyValueHash[normalizedItem.key]) {
            keyValueHash[normalizedItem.key] = [];
          }
          keyValueHash[normalizedItem.key].push([normalizedItem.normalizedValue, epochTime]);
        });
      } else {
        debtList.forEach((sourceAttr: any, key: string) => {
          if (!keyValueHash[key]) {
            keyValueHash[key] = [];
          }
          keyValueHash[key].push([sourceAttr.debt, epochTime]);
        });
      }
    });
    Object.keys(keyValueHash).map(item => {
      const dataPoints = keyValueHash[item];
      const tempDataPointsObj = {
        datapoints: dataPoints,
        field: '@Debt',
        metric: 'Debt',
        props: { '@path': item },
        target: item,
      };
      newDataList.push(toDataFrame(tempDataPointsObj));
    });
    return newDataList;
  };

  toTable = (): any => {
    const latestDebt = this.getLatestDebt();
    const previousDebt = this.getPreviousDebt();
    const resultData: any[] = [];
    const columns = ['debt', 'path', 'increase'];
    if (this.config.isNormalized) {
      const latestNormalizer = this.getLatestNormalizer();
      const previousNormalizer = this.getPreviousNormalizer();
      const latestNormalized = debtNormalizer(latestDebt, latestNormalizer, this.config.referenceCalculation);
      const previousNormalized = debtNormalizer(previousDebt, previousNormalizer, this.config.referenceCalculation);

      latestNormalized.forEach(latestNormalizedItem => {
        const resultObj = {
          path: latestNormalizedItem.key,
          debt: latestNormalizedItem.normalizedValue,
          normalizer: latestNormalizedItem.normalizer,
          increase: 0,
        };
        const previousNormalizedItem = previousNormalized.find(item => item.key === latestNormalizedItem.key);
        if (previousNormalizedItem) {
          resultObj.increase = calculateIncreaseRate(
            latestNormalizedItem.normalizedValue,
            previousNormalizedItem.normalizedValue
          );
        }
        resultData.push(resultObj);
      });
      columns.push('normalizer');
    } else {
      latestDebt.forEach((sourceAttr: any, key: string) => {
        const resultObj = {
          path: key,
          debt: sourceAttr.debt,
          increase: 0,
        };
        const previousDebtItem = previousDebt.get(key);
        if (previousDebtItem) {
          resultObj.increase = calculateIncreaseRate(sourceAttr.debt, previousDebtItem.debt);
        }
        resultData.push(resultObj);
      });
    }

    if (this.config.showNeighbourCount) {
      resultData.forEach(resultItem => {
        let numberOfNeighbours = 0;
        const debtItem = latestDebt.get(resultItem.path);
        if (debtItem) {
          numberOfNeighbours = debtItem.numberOfNeighbours;
        }
        resultItem.numberOfNeighbours = numberOfNeighbours;
      });
      columns.push('numberOfNeighbours');
    }

    return new TableDecorator(columns, resultData);
  };

  toSingleValue = () => {
    const latestDebt = this.getTotalDebt(this.getLatestDebt());
    const previousDebt = this.getTotalDebt(this.getPreviousDebt());

    let latestDebtValue = latestDebt.get('').debt;
    let previousDebtValue = previousDebt.get('').debt;
    if (this.config.isNormalized) {
      let latestNormalizer = this.getLatestNormalizer();
      let previousNormalizer = this.getPreviousNormalizer();
      if (latestNormalizer.length > 1) {
        latestNormalizer = getAsRevisionData(latestNormalizer);
      }
      if (previousNormalizer.length > 1) {
        previousNormalizer = getAsRevisionData(previousNormalizer);
      }

      const latestNormalized = debtNormalizer(latestDebt, latestNormalizer);
      const previousNormalized = debtNormalizer(previousDebt, previousNormalizer);

      latestDebtValue = latestNormalized[0].normalizedValue;
      previousDebtValue = previousNormalized[0].normalizedValue;
    }
    return new SingleValueDecorator(latestDebtValue, previousDebtValue);
  };

  toRawData = () => {
    const latestDebt = this.getLatestDebt();
    const previousDebt = this.getPreviousDebt();
    const latestNormalizer = this.getLatestNormalizer();
    const previousNormalizer = this.getPreviousNormalizer();
    const latestNormalized = debtNormalizer(latestDebt, latestNormalizer);
    const previousNormalized = debtNormalizer(previousDebt, previousNormalizer);
    const resultData: any[] = [];

    latestNormalized.forEach(latestNormalizedItem => {
      const resultObj = {
        path: latestNormalizedItem.key,
        debt: latestNormalizedItem.normalizedValue,
        previousDebt: 0,
        normalizer: latestNormalizedItem.normalizer,
        previousNormalizer: 0,
        increase: 0,
      };
      const previousNormalizedItem = previousNormalized.find(item => item.key === latestNormalizedItem.key);
      if (previousNormalizedItem) {
        resultObj.increase = calculateIncreaseRate(
          latestNormalizedItem.normalizedValue,
          previousNormalizedItem.normalizedValue
        );
        resultObj.previousDebt = previousNormalizedItem.normalizedValue;
        resultObj.previousNormalizer = previousNormalizedItem.normalizer;
      }
      resultData.push(resultObj);
    });
    return resultData;
  };

  private getDebtForPeriod = (currentPeriod: number) => {
    const debtDataHandler: DataHandler = this.dataHandlerList[debtDataIndex].clone();
    debtDataHandler.updateRowList(
      debtDataHandler.getAggregatedRowListWithTimePeriod(currentPeriod, this.config.periodsToConsider)
    );
    const debtHandlerList = [debtDataHandler, this.dataHandlerList[issueTitleDataIndex]];
    const debtCalculator = new DebtCalculator(debtHandlerList, this.config);
    debtCalculator.process();
    return debtCalculator.toRawData();
  };

  private getNormalizationDataForPeriod = (currentPeriod: number) => {
    const normalizerDataHandler = this.dataHandlerList[normalizerDataIndex(this.dataHandlerList.length)];
    if (this.config.applyFolderLevel && !this.config.referenceCalculation) {
      normalizerDataHandler.setFoldersWithFolderLevel(this.config.folderLevel);
    }
    return normalizerDataHandler.getAggregatedRowListWithTimePeriod(currentPeriod, this.config.periodsToConsider);
  };

  private getTotalDebt = (debtList: Map<string, any>): Map<string, any> => {
    const newDebtMap = new Map<string, any>();
    const newAttr = {
      couplingValue: 0,
      cohesionValue: 0,
      debt: 0,
    };
    debtList.forEach((sourceAttr: any, key: string) => {
      newAttr.couplingValue += sourceAttr.couplingValue;
      newAttr.cohesionValue += sourceAttr.cohesionValue;
      newAttr.debt += sourceAttr.debt;
    });
    newDebtMap.set('', newAttr);
    return newDebtMap;
  };

  private getLatestDebt = () => {
    return this.getDebtForPeriod(this.config.latestTimePeriod);
  };

  private getPreviousDebt = () => {
    return this.getDebtForPeriod(this.config.previousTimePeriod);
  };

  private getLatestNormalizer = () => {
    return this.getNormalizationDataForPeriod(this.config.latestTimePeriod);
  };

  private getPreviousNormalizer = () => {
    return this.getNormalizationDataForPeriod(this.config.previousTimePeriod);
  };
}
