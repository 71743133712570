import _ from 'lodash';
import { DetangleCalculator } from './detangle_calculator';
import { DataFrame } from '@grafana/data/src';
import { isFilterValid, nthIndex } from '../utils';
import { FilterType } from '../constants';
import { DataHandler, DataListRefType } from '../data_handler';
import { getDeeperFolderLevel } from './helpers';
import { TableDecorator } from '../decorators/table_decorator';

const debtDataIndex = 0;
const issueTitleDataIndex = 1;

export class DebtCalculator extends DetangleCalculator {
  primaryDataMap: Map<string, any>;
  pathDifferenceLevelFilter = false;
  minNumberOfCommonRefs = 2;
  minNumberOfDiffRefs = 1;

  process = (): void => {
    const couplingDataHandler = this.dataHandlerList[debtDataIndex];
    const issueTitleDataHandler = this.dataHandlerList[issueTitleDataIndex];

    // Check if data has periods and aggregate them if so
    const periodList = couplingDataHandler.getPeriodList();

    if (this.config.aggregatePeriods && (periodList.length !== 1 || periodList[0] !== undefined)) {
      couplingDataHandler.updateRowList(
        couplingDataHandler.getAggregatedRowListWithTimePeriod(
          this.config.latestTimePeriod,
          this.config.periodsToConsider
        )
      );
    }

    const groupedDataArray = couplingDataHandler.getGroupedRowList(this.config.referenceCalculation);
    const groupedPathArray = couplingDataHandler.getGroupedRowList();
    let primaryData = this.config.referenceCalculation ? groupedDataArray : groupedPathArray;

    if (couplingDataHandler.refType === DataListRefType.Author) {
      this.minNumberOfCommonRefs = this.config.minNumberOfCommonCommitters;
      this.minNumberOfDiffRefs = this.config.minNumberOfDiffCommitters;
    } else {
      this.minNumberOfCommonRefs = this.config.minNumberOfCommonRefs;
      this.minNumberOfDiffRefs = this.config.minNumberOfDiffRefs;
    }
    const initialRefFilterCount = this.minNumberOfCommonRefs + this.minNumberOfDiffRefs;
    primaryData = primaryData.filter(item => item.connections.length >= initialRefFilterCount);
    this.pathDifferenceLevelFilter = this.config.pathDifferenceLevel > 0 && !this.config.referenceCalculation;
    this.primaryDataMap = this.generateDataMap(primaryData);
    if (
      issueTitleDataHandler &&
      !this.config.systemOverall &&
      isFilterValid(this.config.issueFilter, FilterType.IssueFilter)
    ) {
      this.applyIssueFilter(issueTitleDataHandler);
    }
    if (this.config.applyFolderLevel && !this.config.referenceCalculation && !this.config.folderLevel.includes(0)) {
      this.applyFolderLevelFilter();
    }
    if (
      !this.config.referenceCalculation &&
      !this.config.systemOverall &&
      isFilterValid(this.config.fileInclusionFilter, FilterType.FileInclusionFilter)
    ) {
      this.applyFileInclusionFilter();
    }
  };

  toTimeSeries = (): DataFrame[] => {
    const newDataList: DataFrame[] = [];
    return newDataList;
  };

  toTable = (): any => {
    const resultData = [];
    const columns = ['path', 'debt', 'couplingValue', 'cohesionValue'];
    this.primaryDataMap.forEach((sourceAttr: any, key: string) => {
      const resultObj = {
        path: key,
        debt: sourceAttr.debt,
        couplingValue: sourceAttr.couplingValue,
        cohesionValue: sourceAttr.cohesionValue,
        numberOfNeighbours: sourceAttr.numberOfNeighbours,
      };
      resultData.push(resultObj);
    });
    if (this.config.showNeighbourCount) {
      columns.push('numberOfNeighbours');
    }
    return new TableDecorator(columns, resultData);
  };

  toSourceTargetPairs = (): any => {
    let resultList = [];
    this.primaryDataMap.forEach((sourceAttr: any, key: string) => {
      for (const targetPair of sourceAttr.targetPairs) {
        resultList.push({
          source: key,
          target: targetPair.target,
          couplingValue: targetPair.couplingValue,
        });
      }
    });
    // File inclusion filter is need to be applied once again here in order to remove targets
    if (
      !this.config.referenceCalculation &&
      !this.config.systemOverall &&
      isFilterValid(this.config.fileInclusionFilter, FilterType.FileInclusionFilter)
    ) {
      this.applyFileInclusionFilter();
    }
    if (this.config.percentageFilter > 0) {
      resultList = _.orderBy(resultList.filter(x => x.couplingValue !== 0), 'couplingValue', 'desc');
      const sizeOfData = resultList.length;
      const startOfFilter = Math.floor(sizeOfData * (this.config.percentageFilter / 100));
      resultList = resultList.slice(0, startOfFilter);
    }
    if (!this.config.referenceCalculation) {
      const folderLevelArray = this.config.folderLevel;
      const maxFolderLevel = Math.max(...folderLevelArray);
      for (const resultItem of resultList) {
        resultItem.target = this.getDeeperFolderLevel(resultItem.target, folderLevelArray, maxFolderLevel);
      }
    }

    const resultHash = {};
    for (let i = 0; i < resultList.length; i++) {
      const tempCouplingObj = resultList[i];
      if (
        tempCouplingObj.source === tempCouplingObj.target ||
        tempCouplingObj.source === '' ||
        tempCouplingObj.target === ''
      ) {
        continue;
      }
      const tempKey = tempCouplingObj.source + '~~' + tempCouplingObj.target;
      if (!resultHash[tempKey]) {
        resultHash[tempKey] = 0;
      }
      resultHash[tempKey] += tempCouplingObj.couplingValue;
    }
    const newResultList = [];

    Object.keys(resultHash).map(item => {
      const tempValue = resultHash[item];
      const keyArray = item.split('~~');
      newResultList.push({
        source: keyArray[0],
        target: keyArray[1],
        couplingValue: tempValue,
      });
    });
    return newResultList;
  };

  toSingleValue: () => any;

  toRawData = (): any => {
    return this.primaryDataMap;
  };

  private applyIssueFilter = (issueTitleDataHandler: DataHandler) => {
    const acceptedIssues = {};
    const issueTitleRegexChecker = new RegExp(this.config.issueFilter);
    issueTitleDataHandler.getRowList().map(row => {
      if (issueTitleRegexChecker.test(row.ref + row.issueTitle)) {
        acceptedIssues[row.ref] = true;
      }
    });
    this.primaryDataMap.forEach((sourceAttr: any, key: string) => {
      if (this.config.referenceCalculation && !acceptedIssues[key]) {
        this.primaryDataMap.delete(key);
      } else if (
        !this.config.referenceCalculation &&
        !sourceAttr.connections.keys().some((item: string | number) => acceptedIssues[item])
      ) {
        this.primaryDataMap.delete(key);
      }
    });
  };

  private applyFileInclusionFilter = () => {
    const regexChecker = new RegExp(this.config.fileInclusionFilter);

    this.primaryDataMap.forEach((sourceAttr: any, key: string) => {
      if (!regexChecker.test(key)) {
        this.primaryDataMap.delete(key);
      }
    });
  };

  private applyFolderLevelFilter() {
    const newMap = new Map<string, any>();
    this.primaryDataMap.forEach((sourceAttr: any, key: string) => {
      const pathDeepLevel = getDeeperFolderLevel(key, this.config.folderLevel);
      if (pathDeepLevel === '') {
        return;
      }
      let newAttr: any;
      if (newMap.has(pathDeepLevel)) {
        newAttr = newMap.get(pathDeepLevel);
      } else {
        newAttr = {
          couplingValue: 0,
          cohesionValue: 0,
          debt: 0,
          numberOfNeighbours: 0,
          targetPairs: [],
        };
      }
      newAttr.couplingValue += sourceAttr.couplingValue;
      newAttr.cohesionValue += sourceAttr.cohesionValue;
      newAttr.debt += sourceAttr.debt;
      newAttr.numberOfNeighbours += sourceAttr.numberOfNeighbours;
      newAttr.targetPairs = newAttr.targetPairs.concat(sourceAttr.targetPairs);
      newMap.set(pathDeepLevel, newAttr);
    });
    this.primaryDataMap = newMap;
  }

  private generateDataMap = (data: any[]): Map<string, any> => {
    const dataMap = new Map<string, any>();
    const secondaryMap = new Map<string, string[]>();
    data.map(x => {
      const dataAttributeObj = {
        connections: new Map<string, number>(),
        sourceProduct: 0,
        pathDifferenceDirectory: '',
        targetPairs: [],
        couplingValue: 0,
        cohesionValue: 0,
        debt: 0,
        numberOfNeighbours: 0,
      };
      x.connections.forEach((connection: { value: number; path: any; ref: any }) => {
        dataAttributeObj.sourceProduct += connection.value * connection.value;
        const itemToPush = this.config.referenceCalculation ? connection.path : connection.ref;
        dataAttributeObj.connections.set(itemToPush, connection.value);
      });
      if (this.pathDifferenceLevelFilter) {
        const indexToComparePath = nthIndex(x.item, '/', this.config.pathDifferenceLevel);
        dataAttributeObj.pathDifferenceDirectory =
          indexToComparePath < 0 ? x.item : x.item.substring(0, indexToComparePath);
      }
      dataMap.set(x.item, dataAttributeObj);
    });
    dataMap.forEach((sourceAttr: any, key: string) => {
      sourceAttr.connections.forEach((connection: any, connectionId: string) => {
        if (secondaryMap.has(connectionId)) {
          const dataList = secondaryMap.get(connectionId);
          dataList.push(key);
          secondaryMap.set(connectionId, dataList);
        } else {
          secondaryMap.set(connectionId, [key]);
        }
      });
    });
    dataMap.forEach((sourceAttr: any, key: string) => {
      let targetPairs = [];
      sourceAttr.connections.forEach((connection: any, connectionId: string) => {
        const dataList = secondaryMap.get(connectionId);
        targetPairs = targetPairs.concat(dataList);
      });
      targetPairs = Array.from(new Set(targetPairs));
      targetPairs = targetPairs.filter(item => key !== item);
      const sourceReferences = Array.from(sourceAttr.connections.keys());
      sourceAttr.targetPairs = [];
      const valuesArray: any[] = Array.from(sourceAttr.connections.values());
      const totalValue = valuesArray.reduce((sum, x) => sum + x, 0);

      const totalRatio = valuesArray.reduce((acc, x) => acc * (1 + x / totalValue), 1);
      // Cohesion Calculation

      const maxValue = Math.max(...valuesArray);
      const ratio = maxValue / totalValue;
      const weight = totalRatio / (ratio + 1);
      sourceAttr.cohesionValue = ratio / weight;
      if (!sourceAttr.cohesionValue) {
        sourceAttr.cohesionValue = 0;
      }

      sourceAttr.numberOfNeighbours = targetPairs.length;
      // Coupling Calculation
      targetPairs.forEach(targetPair => {
        const targetPairObj = dataMap.get(targetPair);
        if (
          this.pathDifferenceLevelFilter &&
          sourceAttr.pathDifferenceDirectory === targetPairObj.pathDifferenceDirectory
        ) {
          return;
        }
        const targetReferences = Array.from(targetPairObj.connections.keys());
        const intersection = sourceReferences.filter(value => targetReferences.includes(value));
        const intersectionLength = intersection.length;
        const sourceDiffCount = sourceReferences.length - intersectionLength;
        const targetDiffCount = targetReferences.length - intersectionLength;

        if (
          this.config.applyDifferentCommonCondition &&
          (sourceDiffCount + targetDiffCount) / intersectionLength < this.config.differentCommonConditionThreshold
        ) {
          return;
        }
        if (intersection.length < this.minNumberOfCommonRefs) {
          return;
        }
        if (sourceDiffCount < this.minNumberOfDiffRefs || targetDiffCount < this.config.minNumberOfDiffRefs) {
          return;
        }
        let sourceTargetProductSum = 0;
        intersection.forEach(reference => {
          const sourceValue = sourceAttr.connections.get(reference);
          const targetValue = targetPairObj.connections.get(reference);
          sourceTargetProductSum += sourceValue * targetValue;
        });
        const couplingValue =
          sourceTargetProductSum / (Math.sqrt(sourceAttr.sourceProduct) * Math.sqrt(targetPairObj.sourceProduct)) || 0;
        if (couplingValue) {
          sourceAttr.targetPairs.push({
            target: targetPair,
            couplingValue: couplingValue,
          });
          sourceAttr.couplingValue += couplingValue;
        }
      });
      let tempCohesionValue = 0;
      if (sourceAttr.cohesionValue && sourceAttr.cohesionValue !== 1) {
        tempCohesionValue = sourceAttr.cohesionValue;
      }
      let tempCouplingValue = 1;
      if (sourceAttr.couplingValue && sourceAttr.couplingValue !== 0) {
        tempCouplingValue = sourceAttr.couplingValue;
      } else {
        sourceAttr.couplingValue = 0;
      }
      sourceAttr.debt = (1 - tempCohesionValue) * tempCouplingValue;
    });
    dataMap.forEach((sourceAttr: any, key: string) => {
      if (
        !isFinite(sourceAttr.cohesionValue) ||
        (!this.config.referenceCalculation && sourceAttr.cohesionValue === 0)
      ) {
        dataMap.delete(key);
      }
    });
    return dataMap;
  };

  private getDeeperFolderLevel = (key: string, folderLevelArray: number[], maxFolderLevel: number) => {
    const folder = '';
    const tempSlashIndex = key.lastIndexOf('/');
    if (tempSlashIndex === -1) {
      return folder;
    }
    let tempFolder = key.substring(0, tempSlashIndex + 1);
    const currentFolderLevel = (tempFolder.match(/\//g) || []).length;
    if (currentFolderLevel > maxFolderLevel) {
      tempFolder = tempFolder.substring(0, nthIndex(tempFolder, '/', maxFolderLevel) + 1);
      return tempFolder;
    }
    if (folderLevelArray.includes(currentFolderLevel)) {
      return tempFolder;
    }
    return folder;
  };
}
