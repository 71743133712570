import React from 'react';
import classNames from 'classnames';
import { PanelModel } from '../../state/PanelModel';
import { DashboardModel } from '../../state/DashboardModel';
import templateSrv from 'app/features/templating/template_srv';
import appEvents from 'app/core/app_events';

export interface DashboardRowProps {
  panel: PanelModel;
  dashboard: DashboardModel;
}

export class DashboardRow extends React.Component<DashboardRowProps, any> {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: this.props.panel.collapsed,
    };

    this.props.dashboard.on('template-variable-value-updated', this.onVariableUpdated);
  }

  componentWillUnmount() {
    this.props.dashboard.off('template-variable-value-updated', this.onVariableUpdated);
  }

  onVariableUpdated = () => {
    this.forceUpdate();
  };

  onToggle = () => {
    this.props.dashboard.toggleRow(this.props.panel);

    this.setState(prevState => {
      return { collapsed: !prevState.collapsed };
    });
  };

  onUpdate = () => {
    this.props.dashboard.processRepeats();
    this.forceUpdate();
  };

  onOpenSettings = () => {
    appEvents.emit('show-modal', {
      templateHtml: `<row-options row="model.row" on-updated="model.onUpdated()" dismiss="dismiss()"></row-options>`,
      modalClass: 'modal--narrow',
      model: {
        row: this.props.panel,
        onUpdated: this.onUpdate,
      },
    });
  };

  onDelete = () => {
    appEvents.emit('confirm-modal', {
      title: 'Delete Row',
      text: 'Are you sure you want to remove this row and all its panels?',
      altActionText: 'Delete row only',
      icon: 'fa-trash',
      onConfirm: () => {
        this.props.dashboard.removeRow(this.props.panel, true);
      },
      onAltAction: () => {
        this.props.dashboard.removeRow(this.props.panel, false);
      },
    });
  };

  render() {
    const classes = classNames({
      'dashboard-row': true,
      'dashboard-row--collapsed': this.state.collapsed,
    });
    const chevronClass = classNames({
      fa: true,
      'fa-chevron-down': !this.state.collapsed,
      'fa-chevron-right': this.state.collapsed,
    });

    let title = templateSrv.replaceWithText(this.props.panel.title, this.props.panel.scopedVars);
    if (title && title.indexOf('{') > -1 && title.indexOf('}') > -1) {
      const codeStart = title.indexOf('{');
      const codeEnd = title.indexOf('}');
      const code = title.substring(codeStart + 1, codeEnd);
      const EVAL_IS_BAD__AVOID_THIS = eval;

      const codeResult = EVAL_IS_BAD__AVOID_THIS(code);

      title = title.substring(0, codeStart) + codeResult + title.substring(codeEnd + 1);
    }
    const canEdit = this.props.dashboard.meta.canEdit === true;

    return (
      <div className={classes}>
        <a
          className="dashboard-row__title pointer"
          onClick={this.onToggle}
          data-status={this.state.collapsed}
          id={'rowLink-' + this.props.panel.id}
        >
          <i className={chevronClass} />
          {title}
        </a>
        {canEdit && (
          <div className="dashboard-row__actions">
            <a className="pointer" onClick={this.onOpenSettings}>
              <i className="gicon gicon-cog" />
            </a>
            <a className="pointer" onClick={this.onDelete}>
              <i className="fa fa-trash" />
            </a>
          </div>
        )}
        {this.state.collapsed === true && (
          <div className="dashboard-row__toggle-target" onClick={this.onToggle}>
            &nbsp;
          </div>
        )}
        {canEdit && <div className="dashboard-row__drag grid-drag-handle" />}
      </div>
    );
  }
}
