import { DetangleFilter } from './detangle_filter';
import { CalculationMethod } from '../constants';

/**
 * Removes data that doesn't fit the regular expression
 *
 * @export
 * @class FileInclusionFilter
 * @extends {DetangleFilter}
 */
export class FileInclusionFilter extends DetangleFilter {
  /**
   * Creates regex checker from the filter expression and updates
   * the datahandler with the rows that fits the regular expression.
   *
   * @returns {void}
   */
  process = (): void => {
    if (!this.shouldApplyFilter()) {
      return;
    }
    const regexChecker = new RegExp(this.config.fileInclusionFilter);
    const filteredRows = this.dataListHandler
      .getRowList()
      .filter(item => regexChecker.test(item.path) || item.path === '');
    this.dataListHandler.updateRowList(filteredRows);
  };

  /**
   * This filter is only applied to base calculations, and the data
   * is represented as folders.
   *
   * @returns {boolean}
   */
  shouldApplyFilter = (): boolean => {
    return (
      this.config.applyFolderLevel &&
      !this.config.systemOverall &&
      this.config.calculationMethod === CalculationMethod.BaseCalculation &&
      this.isFilterValid(this.config.fileInclusionFilter)
    );
  };
}
