import { DataHandler } from '../data_handler';
import { DetangleConfig } from '../detangle_config';
import { DetangleServiceMode } from '../detangle_srv';

export abstract class DetangleCalculator {
  dataHandlerList: DataHandler[];
  config: DetangleConfig;

  constructor(dataHandlerList: DataHandler[], config: DetangleConfig) {
    this.dataHandlerList = dataHandlerList;
    this.config = config;
  }

  abstract process: () => void;

  getAdaptedResult = (mode: DetangleServiceMode) => {
    switch (mode) {
      case DetangleServiceMode.Table:
        return this.toTable();
      case DetangleServiceMode.TimeSeries:
        return this.toTimeSeries();
      case DetangleServiceMode.SingleValue:
        return this.toSingleValue();
      case DetangleServiceMode.SourceTargetPairs:
        return this.toSourceTargetPairs();
      case DetangleServiceMode.RawData:
        return this.toRawData();
      default:
        throw new Error('NotImplemented');
    }
  };

  abstract toTimeSeries: () => any;

  abstract toTable: () => any;

  abstract toSingleValue: () => any;

  toSourceTargetPairs: () => any;

  toRawData: () => any;
}
