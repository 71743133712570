import { DataHandler } from '../data_handler';
import { FilterType } from '../constants';
import { isFilterValid } from '../utils';
import { DetangleConfig } from '../detangle_config';

/**
 * Abstract class of filters.
 * Filters that extends this filter is applied before calculations.
 *
 * @export
 * @abstract
 * @class DetangleFilter
 */
export abstract class DetangleFilter {
  filterType: FilterType;
  dataListHandler: DataHandler;
  config: DetangleConfig;

  /**
   * Creates an instance of DetangleFilter.
   *
   * @param {FilterType} filterType
   * @param {DataHandler} dataList
   * @param {DetangleConfig} config
   * @memberof DetangleFilter
   */
  constructor(filterType: FilterType, dataList: DataHandler, config: DetangleConfig) {
    this.filterType = filterType;
    this.dataListHandler = dataList;
    this.config = config;
  }

  /**
   * The method that applies filter to dataHandler object.
   *
   * @abstract
   * @memberof DetangleFilter
   */
  abstract process: () => void;

  /**
   * This method can be overridden to check if dataHandler or the config is
   * filterable or filtering is desired.
   *
   * @returns {boolean}
   */
  shouldApplyFilter = (): boolean => {
    return true;
  };

  /**
   * Checks if filter expression is a valid expression.
   *
   * @param {*} filterExpression
   * @returns
   */
  protected isFilterValid = (filterExpression: any) => {
    return isFilterValid(filterExpression, this.filterType);
  };
}
