import { DetangleConfig } from './detangle_config';
import { DataHandler } from './data_handler';
import { DetangleFilter, createFilter } from './filters/index';
import { FilterType } from './constants';

/**
 * Manages the application of the soft filters.
 *
 * @export
 * @class DetangleFilterManager
 */
export class DetangleFilterManager {
  config: DetangleConfig;
  dataHandlerList: DataHandler[];

  constructor(dataHandlerList: DataHandler[], detangleConfig: DetangleConfig) {
    this.config = detangleConfig;
    this.dataHandlerList = dataHandlerList;
  }

  /**
   *  Applies filters to data handler lists.
   *
   * @returns {DataHandler[]}
   */
  getFilteredData = (): DataHandler[] => {
    for (let i = 0; i < this.dataHandlerList.length; i++) {
      this.dataHandlerList[i] = this.filterDataList(this.dataHandlerList[i]);
    }
    return this.dataHandlerList;
  };

  /**
   * Pushes single data handler into defined filter pipeline.
   *
   * @param {DataHandler} dataHandler
   * @returns {DataHandler}
   */
  private filterDataList = (dataHandler: DataHandler): DataHandler => {
    if (dataHandler.pathColumnIndex === -1 && dataHandler.parentDirectoryColumnIndex === -1) {
      return dataHandler;
    }
    const filterPipeline = this.getFilterPipeline(dataHandler);
    filterPipeline.forEach(filter => {
      filter.process();
    });
    return dataHandler;
  };

  /**
   * Creates filters to be applied on to data handler.
   *
   * @param {DataHandler} dataHandler
   * @returns {DetangleFilter[]}
   */
  private getFilterPipeline = (dataHandler: DataHandler): DetangleFilter[] => {
    return [
      createFilter(FilterType.FolderLevelFilter, dataHandler, this.config),
      createFilter(FilterType.FileInclusionFilter, dataHandler, this.config),
      createFilter(FilterType.FileExclusionFilter, dataHandler, this.config),
      createFilter(FilterType.MetricRangeFilter, dataHandler, this.config),
    ];
  };
}
