import { DetangleCalculator } from './detangle_calculator';
import { normalizer, calculateIncreaseRate, getAsRevisionData } from './helpers';
// tslint:disable-next-line:import-blacklist
import moment from 'moment';
import { DataFrame, toDataFrame } from '@grafana/data/src';
import { TableDecorator } from '../decorators/table_decorator';
import { SingleValueDecorator } from '../decorators/single_value_decorator';
import { isFilterValid } from '../utils';
import { FilterType } from '../constants';
import { DataRowElement } from '../data_handler';

const valueDataIndex = 0;
const normalizerDataIndex = 1;
// const valueAttribute = 'value';
// const normalizerAttribute = 'normalizer';
// const indexAttribute = 'index';

export class IndexCalculator extends DetangleCalculator {
  effortIndexList = [];
  periodList: number[];

  process = (): void => {
    const valueDataHandler = this.dataHandlerList[valueDataIndex];
    const normalizerDataHandler = this.dataHandlerList[normalizerDataIndex];
    const timePeriodsToConsider = this.config.disableTimePeriod ? 0 : this.config.periodsToConsider;
    if (
      !this.config.referenceCalculation &&
      !this.config.systemOverall &&
      isFilterValid(this.config.fileInclusionFilter, FilterType.FileInclusionFilter)
    ) {
      valueDataHandler.updateRowListHard(this.applyFileInclusionFilter(valueDataHandler.getRowList()));
      if (normalizerDataHandler) {
        normalizerDataHandler.updateRowListHard(this.applyFileInclusionFilter(normalizerDataHandler.getRowList()));
      }
    }
    this.periodList = valueDataHandler.getPeriodList();
    if (this.config.applyFolderLevel) {
      valueDataHandler.setFoldersWithFolderLevel(this.config.folderLevel);
      if (normalizerDataHandler) {
        normalizerDataHandler.setFoldersWithFolderLevel(this.config.folderLevel);
      }
    }
    for (let i = 0; i < this.periodList.length; i++) {
      const tempPeriod = this.periodList[i];
      let aggregatedValueDataList = valueDataHandler.getAggregatedRowListWithTimePeriod(
        tempPeriod,
        timePeriodsToConsider
      );
      let aggregatedNormalizerDataList: DataRowElement[] = [];
      if (normalizerDataHandler) {
        aggregatedNormalizerDataList = normalizerDataHandler.getAggregatedRowListWithTimePeriod(
          tempPeriod,
          timePeriodsToConsider
        );
        if (this.config.aggregateFiles) {
          aggregatedNormalizerDataList = getAsRevisionData(aggregatedNormalizerDataList);
        }
      }
      if (this.config.aggregateFiles) {
        aggregatedValueDataList = getAsRevisionData(aggregatedValueDataList);
      }
      this.effortIndexList[tempPeriod] = normalizer(
        aggregatedValueDataList,
        aggregatedNormalizerDataList,
        this.config.normalizerMultiplier
      );
    }
  };

  toTimeSeries = (): DataFrame[] => {
    const newDataList: DataFrame[] = [];
    const pathList = {};
    Object.keys(this.effortIndexList).map(tempYearMonth => {
      const normalizationList = this.effortIndexList[tempYearMonth];
      const epochTime = moment(tempYearMonth, 'YYYYMM');
      for (const normalizationItem of normalizationList) {
        if (!pathList[normalizationItem.key]) {
          pathList[normalizationItem.key] = [];
        }
        pathList[normalizationItem.key].push([normalizationItem.normalizedValue, epochTime]);
      }
    });
    Object.keys(pathList).map(item => {
      const dataPoints = pathList[item];
      const tempDataPointsObj = {
        datapoints: dataPoints,
        field: '@EffortIndex',
        metric: 'EffortIndex',
        props: { '@path': item },
        target: item,
      };
      newDataList.push(toDataFrame(tempDataPointsObj));
    });
    return newDataList;
  };

  toTable = (): TableDecorator => {
    const latestList = this.getEffortWithFallback(
      this.config.latestTimePeriod,
      this.periodList[this.periodList.length - 1]
    );
    const previousList = this.getEffortWithFallback(
      this.config.previousTimePeriod,
      this.periodList[this.periodList.length - 2]
    );

    const columns = ['path', 'normalizedValue', 'increase', 'normalizer'];
    if (this.config.includeNormalizedOriginal) {
      columns.splice(3, 0, 'value');
    }
    const resultData: any[] = [];
    for (let i = 0; i < latestList.length; i++) {
      const latestData = latestList[i];
      if (latestData.normalizedValue === 0) {
        continue;
      }
      const previousData = previousList.find((item: { key: any }) => item.key === latestData.key);
      let increaseRate = 0;
      if (previousData) {
        increaseRate = calculateIncreaseRate(latestData.normalizedValue, previousData.normalizedValue);
      }
      resultData.push({
        path: latestData.key,
        normalizedValue: latestData.normalizedValue,
        normalizer: latestData.normalizer,
        increase: increaseRate,
        value: latestData.value,
      });
    }
    return new TableDecorator(columns, resultData);
  };

  toSingleValue = (): SingleValueDecorator => {
    const latestList = this.getEffortWithFallback(
      this.config.latestTimePeriod,
      this.periodList[this.periodList.length - 1]
    );
    const previousList = this.getEffortWithFallback(
      this.config.previousTimePeriod,
      this.periodList[this.periodList.length - 2]
    );

    let latestIndexValue = 0;
    let previousIndexValue = 0;
    if (latestList && latestList[0]) {
      latestIndexValue = latestList[0].normalizedValue;
    }
    if (previousList && previousList[0]) {
      previousIndexValue = previousList[0].normalizedValue;
    }
    return new SingleValueDecorator(latestIndexValue, previousIndexValue);
  };

  private getEffortWithFallback(timePeriod: number, fallbackTimePeriod: number) {
    let latestList = this.effortIndexList[timePeriod];
    if (!latestList) {
      if (fallbackTimePeriod) {
        latestList = this.effortIndexList[fallbackTimePeriod];
      }
    }
    return latestList;
  }

  private applyFileInclusionFilter = (rows: DataRowElement[]): DataRowElement[] => {
    const regexChecker = new RegExp(this.config.fileInclusionFilter);
    return rows.filter(x => x.path === '' || regexChecker.test(x.path));
  };
}
