import { DataRowElement } from '../data_handler';
import { nthIndex } from '../utils';

export const normalizer = (
  valueDataList: DataRowElement[],
  normalizerDataList: DataRowElement[],
  normalizerMultiplier = 1000
): NormalizedData[] => {
  let accessKey = 'path';
  if (valueDataList.length > 0 && !valueDataList[0].path) {
    accessKey = 'ref';
  }
  const normalizedDataList: NormalizedData[] = [];
  for (let i = 0; i < valueDataList.length; i++) {
    const tempRow = valueDataList[i];
    const tempNormalizedData = new NormalizedData();
    tempNormalizedData.key = tempRow[accessKey];
    tempNormalizedData.value = tempRow.value;
    normalizedDataList.push(tempNormalizedData);
  }
  for (let i = 0; i < normalizerDataList.length; i++) {
    const tempRow = normalizerDataList[i];
    const tempNormalizedData = normalizedDataList.find(item => item.key === tempRow[accessKey]);
    if (tempNormalizedData) {
      tempNormalizedData.normalizer = tempRow.value;
    }
  }
  normalizedDataList.map(item => {
    item.normalizedValue = item.value / Math.max(1, Math.round((item.normalizer / normalizerMultiplier) * 2) / 2);
  });
  return normalizedDataList;
};

export const debtNormalizer = (
  valueDataList: Map<string, any>,
  normalizerDataList: DataRowElement[],
  referenceCalculation = false
) => {
  const normalizedDataList: NormalizedData[] = [];
  const accessKey = referenceCalculation ? 'ref' : 'path';
  valueDataList.forEach((sourceAttr: any, key: string) => {
    const tempNormalizedData = new NormalizedData();
    tempNormalizedData.key = key;
    tempNormalizedData.value = sourceAttr.debt;
    normalizedDataList.push(tempNormalizedData);
  });
  for (let i = 0; i < normalizerDataList.length; i++) {
    const tempRow = normalizerDataList[i];
    const tempNormalizedData = normalizedDataList.find(item => item.key === tempRow[accessKey]);
    if (tempNormalizedData) {
      tempNormalizedData.normalizer = tempRow.value;
    }
  }
  normalizedDataList.map(item => {
    item.normalizedValue = item.value / Math.max(1, Math.round((item.normalizer / 1000) * 2) / 2);
  });
  return normalizedDataList;
};

export const calculateIncreaseRate = (newValue: number, oldValue: number): number => {
  return Math.floor(((newValue - oldValue) / oldValue) * 100) || 0;
};

export const getDeeperFolderLevel = (path: string, folderLevelArray: any[]) => {
  folderLevelArray.sort((a: number, b: number) => b - a);
  const maxFolderLevel = folderLevelArray[0];
  const folder = '';
  const tempSlashIndex = path.lastIndexOf('/');
  if (tempSlashIndex === -1) {
    return folder;
  }
  let tempFolder = path.substring(0, tempSlashIndex + 1);
  const currentFolderLevel = (tempFolder.match(/\//g) || []).length;
  if (currentFolderLevel > maxFolderLevel) {
    tempFolder = tempFolder.substring(0, nthIndex(tempFolder, '/', maxFolderLevel) + 1);
    return tempFolder;
  }
  if (folderLevelArray.includes(currentFolderLevel)) {
    return tempFolder;
  }
  return folder;
};

export const getAsRevisionData = (dataRowList: DataRowElement[]): DataRowElement[] => {
  if (dataRowList.length === 0) {
    return [];
  }
  const totalRow = dataRowList[0];
  let totalValue = 0;
  dataRowList.forEach(element => {
    totalValue += element.value;
  });
  totalRow.path = '';
  totalRow.ref = '';
  totalRow.value = totalValue;
  return [totalRow];
};

export class NormalizedData {
  value = 0;
  normalizer = 0;
  normalizedValue = 0;
  key: string;
}
