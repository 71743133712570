import { TemplateSrv } from 'app/features/templating/template_srv';
import { CalculationMethod, FilterType, EstimationMethod, TimeGranularity } from './constants';
import { isInt } from './utils';

const detangleRatioDefault = 2;
const periodToConsiderDefault = 3;

/**
 * This class is converts panel settings to strongly typed
 * attributes.
 *
 * @export
 * @class DetangleConfig
 */
export class DetangleConfig {
  private configJson: any;
  private templateSrv: TemplateSrv;

  calculationMethod: CalculationMethod;
  timeGranulartiy: TimeGranularity;
  latestTimePeriod: number;
  previousTimePeriod: number;
  minNumberOfDiffRefs: number;
  minNumberOfCommonRefs: number;
  minNumberOfDiffCommitters: number;
  minNumberOfCommonCommitters: number;
  pathDifferenceLevel: number;
  periodsToConsider: number;
  debtCriticalPercentage: number;
  debtWarningPercentage: number;
  detangleRatio: number;
  folderLevel: number[];
  metricRange: number[];
  systemOverall: boolean;
  applyFolderLevel: boolean;
  aggregatePeriods: boolean;
  showNeighbourCount: boolean;
  fileInclusionFilter: string;
  fileExclusionFilter: string;
  issueFilter: string;
  referenceCalculation: boolean;
  isNormalized: boolean;
  estimationMethod: EstimationMethod;
  percentageFilter: number;
  normalizerMultiplier: number;
  distinctDeveloperCount: number[];
  includeNormalizedOriginal: boolean;
  calculateMetricRatios: boolean;
  showPmData: boolean;
  aggregateFiles: boolean;
  disableTimePeriod: boolean;
  multiProject: boolean;
  applyDifferentCommonCondition: boolean;
  differentCommonConditionThreshold: number;

  constructor(templateSrv: TemplateSrv, configJson: any) {
    this.configJson = configJson;
    this.templateSrv = templateSrv;
    this.init();
  }

  /**
   * Initializes the object parameters and set them from json attributes.
   *
   */
  private init = (): void => {
    this.calculationMethod = this.getCalculationMethod();
    this.latestTimePeriod = this.getLatestTimePeriod();
    this.previousTimePeriod = this.getPreviousTimePeriod();
    this.minNumberOfDiffRefs = this.getMinNumberOfDiffRefs();
    this.minNumberOfCommonRefs = this.getMinNumberOfCommonRefs();
    this.minNumberOfDiffCommitters = this.getMinNumberOfDiffCommitters();
    this.minNumberOfCommonCommitters = this.getMinNumberOfCommonCommitters();
    this.pathDifferenceLevel = this.getPathDifferenceLevel();
    this.periodsToConsider = this.getNumberOfPeriodsToConsider();
    this.debtCriticalPercentage = this.getDebtCriticalPercentage();
    this.debtWarningPercentage = this.getDebtWarningPercentage();
    this.detangleRatio = this.getDetangleRatio();
    this.multiProject = this.getMultiProjectConfig();
    this.folderLevel = this.getFolderLevel();
    this.metricRange = this.getMetricRange();
    this.systemOverall = this.getSystemOverallConfig();
    this.applyFolderLevel = this.getApplyFolderLevelConfig();
    this.showNeighbourCount = this.getShowNeighbourCountConfig();
    this.fileInclusionFilter = this.getFileInclusionFilter();
    this.fileExclusionFilter = this.getFileExclusionFilter();
    this.issueFilter = this.getIssueFilter();
    this.referenceCalculation = this.getReferenceCalculationConfig();
    this.isNormalized = this.getNormalizationStatus();
    this.estimationMethod = this.getEstimationMethod();
    this.percentageFilter = this.getPercentageFilter();
    this.normalizerMultiplier = this.getNormalizerMultiplier();
    this.distinctDeveloperCount = this.getDistinctDeveloperCount();
    this.includeNormalizedOriginal = this.getIncludeNormalizedOriginalConfig();
    this.calculateMetricRatios = this.getMetricRatioConfig();
    this.timeGranulartiy = this.getTimeGranularityConfig();
    this.showPmData = this.getShowPmDataConfig();
    this.aggregateFiles = this.getAggregateDataConfig();
    this.aggregatePeriods = this.getAggregatePeriodsConfig();
    this.disableTimePeriod = this.getDisableTimePeriodConfig();
    this.applyDifferentCommonCondition = this.getDifferentCommonConditionConfig();
    this.differentCommonConditionThreshold = this.getDifferentCommonConditionThreshold();
  };

  /**
   * Gets the clone of the current config.
   * @returns {DetangleConfig}
   */
  clone = (): DetangleConfig => {
    return new DetangleConfig(this.templateSrv, this.configJson);
  };

  /**
   * Gets auto setted "Latest Time Period" global filter from templating variables.
   *
   * @returns {number}
   */
  private getLatestTimePeriod = (): number => {
    return DetangleConfig.getIntegerVariable(this.getVariableFromTemplateSrv(FilterType.LatestTimePeriodFilter));
  };

  /**
   * Gets auto setted "Previous Time Period" global filter from templating variables.
   *
   * @returns {number}
   */
  private getPreviousTimePeriod = (): number => {
    return DetangleConfig.getIntegerVariable(this.getVariableFromTemplateSrv(FilterType.PreviousTimePeriodFilter));
  };

  /**
   * Gets "Different references per file pair" global filter from templating variables.
   *
   * @returns {number}
   */
  private getMinNumberOfDiffRefs = (): number => {
    return DetangleConfig.getIntegerVariable(this.getVariableFromTemplateSrv(FilterType.MinDifferentConnectionsFilter));
  };

  /**
   * Gets "Common references per file pair" global filter from templating variables.
   *
   * @returns {number}
   */
  private getMinNumberOfCommonRefs = (): number => {
    return DetangleConfig.getIntegerVariable(this.getVariableFromTemplateSrv(FilterType.MinCommonConnectionsFilter));
  };

  /**
   * Gets "Different references per file pair" global filter from templating variables.
   *
   * @returns {number}
   */
  private getMinNumberOfDiffCommitters = (): number => {
    return DetangleConfig.getIntegerVariable(this.getVariableFromTemplateSrv(FilterType.MinDifferentCommittersFilter));
  };

  /**
   * Gets "Common references per file pair" global filter from templating variables.
   *
   * @returns {number}
   */
  private getMinNumberOfCommonCommitters = (): number => {
    return DetangleConfig.getIntegerVariable(this.getVariableFromTemplateSrv(FilterType.MinCommonCommittersFilter));
  };

  /**
   * Gets "Path Difference Level per pair" global filter from templating variables.
   *
   * @returns {number}
   */
  private getPathDifferenceLevel = (): number => {
    return DetangleConfig.getIntegerVariable(this.getVariableFromTemplateSrv(FilterType.PathDifferenceLevelFilter));
  };

  /**
   * Gets "Time Periods To consider" global filter from templating variables.
   * It returns the minus one of actual value, because in the implementation we need the number of
   * additional periods to be taken into consideration.
   *
   * @returns  {number}
   */
  private getNumberOfPeriodsToConsider = (): number => {
    return (
      DetangleConfig.getIntegerVariable(
        this.getVariableFromTemplateSrv(FilterType.NumberOfPeriodsToConsiderFilter),
        periodToConsiderDefault
      ) - 1
    );
  };

  /**
   * Gets "Debt Critical" global filter from templating variables.
   *
   * @returns {number}
   */
  private getDebtCriticalPercentage = (): number => {
    return DetangleConfig.getIntegerVariable(this.getVariableFromTemplateSrv(FilterType.DebtCriticalPercentageFilter));
  };

  /**
   * Gets Debt Warning" global filter from templating variables.
   *
   * @returns {number}
   */
  private getDebtWarningPercentage = (): number => {
    return DetangleConfig.getIntegerVariable(this.getVariableFromTemplateSrv(FilterType.DebtWarningPercentageFilter));
  };

  /**
   * Gets "Detangle Ratio" global filter from templating variables.
   *
   * @returns {number}
   */
  private getDetangleRatio = (): number => {
    return DetangleConfig.getIntegerVariable(
      this.getVariableFromTemplateSrv(FilterType.DetangleRatioFilter),
      detangleRatioDefault
    );
  };

  /**
   *  Gets "Folder Level" global filter from templating variables.
   *
   * @returns {number[]}
   */
  private getFolderLevel = (): number[] => {
    let folderLevel = this.getVariableFromTemplateSrv(FilterType.FolderLevelFilter);
    if (this.configJson.folderLevelData) {
      folderLevel = this.configJson.folderLevelData;
    }
    let folderLevelArray: number[] = [];
    const isFolderLevelInt = isInt(folderLevel);
    if (isFolderLevelInt) {
      folderLevelArray.push(DetangleConfig.getIntegerVariable(folderLevel));
    } else {
      if (folderLevel.includes('+')) {
        const tempFolderLevelArray = folderLevel.split(' + ');
        folderLevelArray = tempFolderLevelArray.map(item => DetangleConfig.getIntegerVariable(item));
      }
    }
    if (this.multiProject) {
      folderLevelArray = folderLevelArray.map(x => x + 1);
    }
    return folderLevelArray;
  };

  /**
   *  Gets "Folder Level" global filter from templating variables.
   *
   * @returns {number[]}
   */
  private getMetricRange = (): number[] => {
    const metricRange = this.getVariableFromTemplateSrv(FilterType.MetricRangeFilter);
    let metricRangeArray: number[] = [];
    const isMetricRangeInt = isInt(metricRange);
    if (isMetricRangeInt) {
      metricRangeArray.push(DetangleConfig.getIntegerVariable(metricRange));
    } else {
      if (metricRange.includes('-') && metricRange !== '-') {
        const tempMetricRangeArray = metricRange.split('-');
        metricRangeArray = tempMetricRangeArray.map(item => DetangleConfig.getIntegerVariable(item));
      }
    }
    if (metricRangeArray.length === 0) {
      metricRangeArray.push(1);
    }
    return metricRangeArray;
  };

  /**
   *  Gets normalizer multiplier, which 1K by default.
   *
   * @returns {number[]}
   */
  private getNormalizerMultiplier = (): number => {
    return this.configJson.normalizerMultiplier || 1000;
  };

  /**
   * Gets the calculation method for the adaptation and calculations.
   *
   * @returns {CalculationMethod}
   */
  private getCalculationMethod = (): CalculationMethod => {
    if (this.isPeriodicDebtCalculation()) {
      return CalculationMethod.PeriodicDebtCalculation;
    }
    if (this.isDebtCalculation()) {
      return CalculationMethod.DebtCalculation;
    }
    if (this.isIndexCalculation()) {
      return CalculationMethod.IndexCalculation;
    }
    if (this.isEstimationCalculation()) {
      return CalculationMethod.EstimationCalculation;
    }
    if (this.isTrendCalculation()) {
      return CalculationMethod.TrendCalculation;
    }
    if (this.isKnowledgeDistributionCalculation()) {
      return CalculationMethod.KnowledgeCalculation;
    }
    if (this.isCombinationCalculation()) {
      return CalculationMethod.CombinationCalculation;
    }
    if (this.isCostCalculation()) {
      return CalculationMethod.CostCalculation;
    }
    return CalculationMethod.BaseCalculation;
  };

  /**
   * Gets the estimation method for estimation calculations.
   *
   * @returns {EstimationMethod}
   */
  private getEstimationMethod = (): EstimationMethod => {
    switch (this.configJson.estimationMethod) {
      case 'average':
        return EstimationMethod.AvgEstimatedEffort;
      case 'estimatedEffort':
        return EstimationMethod.RawEstimatedEffort;
      case 'latest':
        return EstimationMethod.LatestEstimatedEffort;
      case 'criticalLocRatio':
        return EstimationMethod.CriticalLocRatio;
      case 'warningLocRatio':
        return EstimationMethod.WarningLocRatio;
      default:
        return EstimationMethod.RawEstimatedEffort;
    }
  };

  /**
   * Gets if the calculation method is a periodic debt calculation.
   *
   * @returns {boolean}
   */
  private isPeriodicDebtCalculation = (): boolean => {
    return this.configJson.periodicDebtCalculation;
  };

  /**
   * Gets if the calculation method is a debt calculation.
   *
   * @returns {boolean}
   */
  private isDebtCalculation = (): boolean => {
    return this.configJson.coupling;
  };

  /**
   * Gets if the calculation method is an index calculation.
   *
   * @returns {boolean}
   */
  private isIndexCalculation = (): boolean => {
    return this.configJson.indexCalculation;
  };

  /**
   * Gets if the calculation method is an estimation calculation.
   *
   * @returns {boolean}
   */
  private isEstimationCalculation = (): boolean => {
    return this.configJson.estimationCalculation;
  };

  /**
   * Gets if the calculation method is an trend calculation.
   *
   * @returns {boolean}
   */
  private isTrendCalculation = (): boolean => {
    return this.configJson.trend;
  };

  /**
   * Gets if the calculation method is a knowledge distribution calculation.
   *
   * @returns {boolean}
   */
  private isKnowledgeDistributionCalculation = (): boolean => {
    return this.configJson.knowledgeDistribution;
  };

  /**
   * Gets if the calculation method is a combination calculation.
   *
   * @returns {boolean}
   */
  private isCombinationCalculation = (): boolean => {
    return this.configJson.combinationCalculation;
  };

  /**
   * Gets if the calculation method is a cost calculation.
   *
   * @returns {boolean}
   */
  private isCostCalculation = (): boolean => {
    return this.configJson.costCalculation;
  };

  /**
   * Gets if the current calculation should be done over all the system.
   *
   * @returns {boolean}
   */
  private getSystemOverallConfig = (): boolean => {
    return this.configJson.system;
  };

  /**
   * Gets if PM data needs to be shown.
   *
   * @returns {boolean}
   */
  private getShowPmDataConfig = (): boolean => {
    return this.configJson.showPM;
  };

  /**
   * Gets if aggregate data config has selected.
   *
   * @returns {boolean}
   */
  private getAggregateDataConfig = (): boolean => {
    return this.configJson.aggregateFiles;
  };

  /**
   * Gets if aggregate time periods config has selected.
   *
   * @returns {boolean}
   */
  private getAggregatePeriodsConfig = (): boolean => {
    return this.configJson.aggregatePeriods;
  };

  /**
   * Gets if disable considered time period data config has selected.
   *
   * @returns {boolean}
   */
  private getDisableTimePeriodConfig = (): boolean => {
    return this.configJson.disableTimePeriod;
  };

  /**
   * Gets if multi project config has selected.
   *
   * @returns {boolean}
   */
  private getMultiProjectConfig = (): boolean => {
    return this.configJson.multiProject;
  };

  /**
   * Gets if different vs. common condition should be applied on coupling calculation.
   *
   * @returns {boolean}
   */
  private getDifferentCommonConditionConfig = (): boolean => {
    if (this.configJson.applyDifferentCommonCondition) {
      return true;
    }
    return false;
  };

  /**
   * Gets if multiplier for different vs. common condition.
   *
   * @returns {boolean}
   */
  private getDifferentCommonConditionThreshold = (): number => {
    return this.configJson.differentCommonConditionThreshold || 0.75;
  };

  /**
   * Gets if the calculation should be done on folders.
   *
   * @returns {boolean}
   */
  private getApplyFolderLevelConfig = (): boolean => {
    return this.configJson.applyFolderLevel;
  };

  /**
   * Gets if the number of neighbours should be shown.
   *
   * @returns {boolean}
   */
  private getShowNeighbourCountConfig = (): boolean => {
    return this.configJson.showNeighbourCount;
  };

  /**
   * Gets "File Inclusion" global filter from templating variables.
   *
   * @returns {string}
   */
  private getFileInclusionFilter = (): string => {
    return this.getVariableFromTemplateSrv(FilterType.FileInclusionFilter);
  };

  /**
   * Gets "File Exclusion" global filter from templating variables.
   *
   * @returns {string}
   */
  private getFileExclusionFilter = (): string => {
    return this.getVariableFromTemplateSrv(FilterType.FileExclusionFilter);
  };

  /**
   * Gets "Issue Filter" global filter from templating variables.
   *
   * @returns {string}
   */
  private getIssueFilter = (): string => {
    return this.getVariableFromTemplateSrv(this.configJson.issueTitleData);
  };

  /**
   * Gets if debt calculations should be done on a reference type
   * (issue, committer, temporal) rather than path.
   *
   * @returns {boolean}
   */
  private getReferenceCalculationConfig = (): boolean => {
    return this.configJson.referenceCalculation;
  };

  /**
   * Gets if the calculations should be normalized.
   *
   * @returns {boolean}
   */
  private getNormalizationStatus = (): boolean => {
    return this.configJson.isNormalized;
  };

  /**
   * Gets the panel settings for knowledge distribution calculations,
   * which decides the distinct number of developers considered for
   * knowledge balances and/or knowledge islands.
   *
   * @returns {number[]}
   */
  private getDistinctDeveloperCount = (): number[] => {
    if (!this.configJson.developerCount) {
      return [];
    }
    const tempDeveloperCountArray = this.configJson.developerCount.split(',');
    const developerCountArray = tempDeveloperCountArray.map((item: string) => DetangleConfig.getIntegerVariable(item));
    return developerCountArray;
  };

  /**
   * Gets the config of inclusion of original normalized metric value.
   *
   * @returns {boolean}
   */
  private getIncludeNormalizedOriginalConfig = (): boolean => {
    if (!this.configJson.includeNormalizedOriginal) {
      return false;
    }
    return this.configJson.includeNormalizedOriginal;
  };

  /**
   * Gets the config to calculate custom metric ratios.
   *
   * @returns {boolean}
   */
  private getMetricRatioConfig = (): boolean => {
    if (!this.configJson.calculateMetricRatio) {
      return false;
    }
    return this.configJson.calculateMetricRatio;
  };

  /**
   * Gets the time granularity config from the global filter.
   *
   * @returns {TimeGranularity}
   */
  private getTimeGranularityConfig = (): TimeGranularity => {
    const timeGranularityText = this.getVariableFromTemplateSrv(FilterType.TimeGranularity);
    if (timeGranularityText === TimeGranularity.Quarter) {
      return TimeGranularity.Quarter;
    } else if (timeGranularityText === TimeGranularity.Month) {
      return TimeGranularity.Month;
    } else {
      return TimeGranularity.Year;
    }
  };

  /**
   * Gets percentage global filter from templating variables.
   *
   * @returns {number}
   */
  private getPercentageFilter = (): number => {
    return (
      this.configJson.couplingPercentage ||
      DetangleConfig.getIntegerVariable(this.getVariableFromTemplateSrv(FilterType.CouplingPercentage))
    );
  };

  /**
   * Gets integer variable from templating variables.
   *
   * @param {string} valueStr
   * @param {number} [defaultValue=0]
   * @returns {number}
   */
  private static getIntegerVariable = (valueStr: string, defaultValue = 0): number => {
    return parseInt(valueStr, 10) || defaultValue;
  };

  /**
   * Gets desired variable from templating variables.
   *
   * @param {string} variableName
   * @returns {*}
   */
  private getVariableFromTemplateSrv = (variableName: string): any => {
    return this.templateSrv.replaceWithText(variableName, null);
  };
}
