import { DetangleFilter } from './detangle_filter';

/**
 * The filter keeps the rows, values of which in the given metric range.
 *
 * @export
 * @class MetricRangeFilter
 * @extends {DetangleFilter}
 */
export class MetricRangeFilter extends DetangleFilter {
  /**
   * User can specify 1 or 2 values for this filter.
   *
   * @returns {void}
   */
  process = (): void => {
    if (!this.shouldApplyFilter()) {
      return;
    }
    let tempRows = this.dataListHandler.getRowList();
    const metricRangeArray = this.config.metricRange;
    if (metricRangeArray.length === 2) {
      const min = metricRangeArray[0];
      const max = metricRangeArray[1];
      tempRows = tempRows.filter(item => item.value >= min && item.value <= max);
    } else if (metricRangeArray.length === 1) {
      const min = metricRangeArray[0];
      tempRows = tempRows.filter(item => item.value >= min);
    }
    this.dataListHandler.updateRowListHard(tempRows);
  };

  /**
   * This filter can be applied to any type of calculation
   * as long as the given metric range is valid.
   *
   * @returns {boolean}
   */
  shouldApplyFilter = (): boolean => {
    return (
      this.isFilterValid(this.config.metricRange) &&
      this.config.metricRange.length > 0 &&
      this.config.metricRange[this.config.metricRange.length - 1] !== 0
    );
  };
}
