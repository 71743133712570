import _ from 'lodash';
import $ from 'jquery';
import { MetricsPanelCtrl } from 'app/plugins/sdk';
import config from 'app/core/config';
import { transformDataToTable } from './transformers';
import { tablePanelEditor } from './editor';
import { columnOptionsTab } from './column_options';
import { TableRenderer } from './renderer';
import { isTableData } from '@grafana/data';
import { TemplateSrv } from 'app/features/templating/template_srv';
import { DetangleServiceMode } from '../../../core/services/detangle/detangle_srv';
import { issueIdTitleChecker, dataListAggregator } from 'app/core/services/detangle/utils';
import appEvents from 'app/core/app_events';

class TablePanelCtrl extends MetricsPanelCtrl {
  static templateUrl = 'module.html';

  pageIndex: number;
  dataRaw: any;
  table: any;
  renderer: any;
  remainingTries: number;

  sortingOrder: any = [];
  couplingMetrics: any = [];
  targetSelections: any = [];
  cohesionCalculationMethods: any = [];
  customMetrics: any = [];

  panelDefaults: any = {
    targets: [{}],
    transform: 'timeseries_to_columns',
    pageSize: null,
    showHeader: true,
    ratio: false,
    styles: [
      {
        type: 'date',
        pattern: 'Time',
        alias: 'Time',
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
      },
      {
        unit: 'short',
        type: 'number',
        alias: '',
        decimals: 2,
        colors: ['rgba(245, 54, 54, 0.9)', 'rgba(237, 129, 40, 0.89)', 'rgba(50, 172, 45, 0.97)'],
        colorMode: null,
        pattern: '/.*/',
        thresholds: [],
      },
    ],
    columns: [],
    scroll: true,
    fontSize: '100%',
    sort: { col: 0, desc: true },
    /**
     * @detangleEdit start
     * @author Ural
     */
    // Detangle Options
    detangle: {
      coupling: false,
      overview: false,
      combined: false,
      cost: false,
      combination: false,
      calculateMetricRatio: false,
      maintainabilityIndex: false,
      knowledgeDistribution: false,
      qualityEffort: false,
      applyFolderLevel: false,
      disableTimePeriod: false,
      ratio: false,
      softFilter: false,
      showNeighbourCount: false,
      includeNormalizedOriginal: false,
      referenceCalculation: false,
      applyDifferentCommonCondition: false,
      differentCommonConditionThreshold: 0.75,
      sortingOrder: 'desc',
      limit: null,
      metric: 'coupling',
      sourceType: '$issue_type',
      targetType: '$target_issue_type',
      sourceTypeData: '',
      targetTypeData: '',
      author: '$author',
      authorData: '',
      yearData: '',
      target: 'issue',
      minIssuesPerFile: null,
      minIssuesData: '',
      minFilesPerIssue: null,
      minFilesData: '',
      issueTitle: '$issue_title',
      issueTitleData: '',
      fileExcludeFilter: '$file_exclude',
      fileExcludeFilterData: '',
      metricRange: '$metric_range',
      metricRangeData: '',
      fileGroup: '$file_group',
      fileGroupData: '',
      localFilter: '',
      cohesionCalculationMethod: 'standard',
      additionalMetric: false,
      isAuthorDashboard: false,
      isCustom: false,
      customMetric: '',
      issueSearch: false,
      issueSearchQueryOrder: false,
      multiProject: false,
    },
    /**
     * @detangleEdit end
     * @author Ural
     */
  };

  /** @ngInject */
  constructor(
    $scope: any,
    $injector: any,
    templateSrv: TemplateSrv,
    private detangleSrvNew,
    private annotationsSrv: any,
    private $sanitize: any,
    private variableSrv: any
  ) {
    super($scope, $injector);

    this.pageIndex = 0;

    if (this.panel.styles === void 0) {
      this.panel.styles = this.panel.columns;
      this.panel.columns = this.panel.fields;
      delete this.panel.columns;
      delete this.panel.fields;
    }

    _.defaults(this.panel, this.panelDefaults);

    this.events.on('data-received', this.onDataReceived.bind(this));
    this.events.on('data-error', this.onDataError.bind(this));
    this.events.on('data-snapshot-load', this.onDataReceived.bind(this));
    this.events.on('init-edit-mode', this.onInitEditMode.bind(this));
    this.events.on('init-panel-actions', this.onInitPanelActions.bind(this));

    /**
     * @detangleEdit start
     * @author Ural
     */
    this.remainingTries = 2;
    this.sortingOrder = [{ text: 'Ascending', value: 'asc' }, { text: 'Descending', value: 'desc' }];

    this.couplingMetrics = [
      { text: 'Coupling Value', value: 'coupling' },
      { text: 'Num. of Couples', value: 'couplecounts' },
      { text: 'Cohesion Value', value: 'cohesion' },
    ];

    this.targetSelections = [{ text: 'Issues|Committers', value: 'issue' }, { text: 'Files', value: 'file' }];

    this.cohesionCalculationMethods = [
      {
        text: 'Standard',
        value: 'standard',
      },
      {
        text: 'Double',
        value: 'double',
      },
    ];

    this.customMetrics = [
      {
        text: 'NormalizedConstantVSConstant',
        value: 'NormalizedConstantVSConstant',
      },
      {
        text: 'NormalizedDebtVSConstant',
        value: 'NormalizedDebtVSConstant',
      },
      {
        text: 'DebtVSConstant',
        value: 'DebtVSConstant',
      },
      {
        text: 'DebtCombined',
        value: 'Combined',
      },
      {
        text: 'FeatureDebtVSCouplingVSCohesion',
        value: 'FeatureDebtVSCouplingVSCohesion',
      },
      {
        text: 'EffortEstimation',
        value: 'EffortEstimation',
      },
      {
        text: 'EffortIndex',
        value: 'EffortIndex',
      },
      {
        text: 'Ratio',
        value: 'Ratio',
      },
      {
        text: 'Combination',
        value: 'Combination',
      },
      {
        text: 'Cost',
        value: 'Cost',
      },
      {
        text: 'KnowledgeDistribution',
        value: 'KnowledgeDistribution',
      },
    ];
    /**
     * @detangleEdit end
     * @author Ural
     */
  }

  onInitEditMode() {
    this.addEditorTab('Options', tablePanelEditor, 2);
    this.addEditorTab('Column Styles', columnOptionsTab, 3);
    this.addEditorTab('Detangle', 'public/app/plugins/panel/table/detangle.html', 5);
  }

  onInitPanelActions(actions: any[]) {
    actions.push({ text: 'Export CSV', click: 'ctrl.exportCsv()' });
  }

  issueQueries(datasource: any) {
    this.pageIndex = 0;

    if (this.panel.transform === 'annotations') {
      return this.annotationsSrv
        .getAnnotations({
          dashboard: this.dashboard,
          panel: this.panel,
          range: this.range,
        })
        .then((anno: any) => {
          this.loading = false;
          this.dataRaw = anno;
          this.pageIndex = 0;
          this.render();
          return { data: this.dataRaw }; // Not used
        });
    }

    return super.issueQueries(datasource);
  }

  onDataError(err: any) {
    this.dataRaw = [];
    this.render();
  }

  calculateRatio(dataList) {
    if (dataList.lenght < 2) {
      return;
    }

    const pathList = {};
    for (let i = 0; i < dataList.length; i++) {
      // let tempColumns = dataList[i].columns;
      const tempRows = dataList[i].rows;
      const valueIndex = 1;
      for (let rowIndex = 0; rowIndex < tempRows.length; rowIndex++) {
        const tempRow = tempRows[rowIndex];
        const tempPath = tempRow[0];
        const tempValue = tempRow[valueIndex];
        if (!pathList[tempPath]) {
          pathList[tempPath] = {};
        }
        pathList[tempPath]['value' + i] = tempValue;
      }
    }
    const generatedRowList = [];
    let returnDataList: any = {};
    returnDataList.columns = [
      { text: '@path', filterable: true },
      { text: 'Ratio' },
      { text: 'Metric1' },
      { text: 'Metric2' },
    ];
    returnDataList.columnMap = {
      '@path': { text: '@path', filterable: true },
      Ratio: { text: 'Ratio' },
      Metric1: { text: 'Metric1' },
      Metric2: { text: 'Metric2' },
    };
    if (dataList.length > 3) {
      Object.keys(pathList).map(item => {
        const tempValueHash = pathList[item];
        let tempRatio = 0;
        let tempRatio2 = 0;
        if (tempValueHash.value0 && tempValueHash.value1 && tempValueHash.value0 !== 0 && tempValueHash.value1 !== 0) {
          tempRatio = tempValueHash.value0 / tempValueHash.value1;
        }
        if (tempValueHash.value2 && tempValueHash.value3 && tempValueHash.value2 !== 0 && tempValueHash.value3 !== 0) {
          tempRatio2 = tempValueHash.value2 / tempValueHash.value3;
        }
        generatedRowList.push({
          0: item,
          1: tempRatio,
          2: tempValueHash.value0 || 0,
          3: tempValueHash.value1 || 0,
          4: tempRatio2,
          5: tempValueHash.value2 || 0,
          6: tempValueHash.value3 || 0,
        });
      });
      returnDataList.columns.push({ text: 'Ratio2' });
      returnDataList.columns.push({ text: 'Metric3' });
      returnDataList.columns.push({ text: 'Metric4' });
      returnDataList.columnMap['Ratio2'] = { text: 'Ratio2' };
      returnDataList.columnMap['Metric3'] = { text: 'Metric3' };
      returnDataList.columnMap['Metric4'] = { text: 'Metric4' };
    } else {
      Object.keys(pathList).map(item => {
        const tempValueHash = pathList[item];
        let tempRatio = 0;
        if (tempValueHash.value0 && tempValueHash.value1 && tempValueHash.value0 !== 0 && tempValueHash.value1 !== 0) {
          tempRatio = tempValueHash.value0 / tempValueHash.value1;
        }
        generatedRowList.push({
          0: item,
          1: tempRatio,
          2: tempValueHash.value0 || 0,
          3: tempValueHash.value1 || 0,
        });
      });
    }
    returnDataList.rows = generatedRowList;

    returnDataList.type = 'table';
    returnDataList = [returnDataList];
    return returnDataList;
  }

  onDataReceived(dataList: any) {
    /**
     * @detangleEdit start
     * @author Ural
     */
    function combineResults(dataArray) {
      const pathList = {};
      const nameArray = [
        'DefectImpact',
        'BugIndex',
        'FeatureNewCode',
        'SystemNewCode',
        'FeatureDebtIndex',
        'CommitterFrictionIndex',
      ];
      const debtIndexValues = ['DefectImpact', 'FeatureDebtIndex', 'CommitterFrictionIndex'];
      for (let i = 0; i < dataArray.length; i++) {
        const tempColumns = dataArray[i].columns;
        const tempRows = dataArray[i].rows;
        const valueIndex = debtIndexValues.includes(nameArray[i]) ? 1 : tempColumns.length - 1;
        for (let rowIndex = 0; rowIndex < tempRows.length; rowIndex++) {
          const tempRow = tempRows[rowIndex];
          const tempPath = tempRow[0];
          const tempValue = tempRow[valueIndex];
          if (!pathList[tempPath]) {
            pathList[tempPath] = {};
          }
          pathList[tempPath][nameArray[i]] = tempValue;
        }
      }
      const generatedRowList = [];
      Object.keys(pathList).map(item => {
        const tempValueHash = pathList[item];
        if (tempValueHash.FeatureDebtIndex) {
          generatedRowList.push({
            0: item,
            1: tempValueHash.BugIndex || 0,
            2: tempValueHash.DefectImpact || 0,
            3: tempValueHash.FeatureDebtIndex || 0,
            4: tempValueHash.FeatureNewCode || 0,
            5: tempValueHash.CommitterFrictionIndex || 0,
            6: tempValueHash.SystemNewCode || 0,
          });
        }
      });

      let returnDataList: any = {};
      returnDataList.rows = generatedRowList;
      returnDataList.columns = [
        { text: '@path', filterable: true },
        { text: 'BugIndex' },
        { text: 'DefectImpact' },
        { text: 'FeatureDebtIndex' },
        { text: 'FeatureNewCode' },
        { text: 'CommitterFrictionIndex' },
        { text: 'SystemNewCode' },
      ];
      returnDataList.columnMap = {
        '@path': { text: '@path', filterable: true },
        Ratio: { text: 'BugIndex' },
        Metric1: { text: 'DefectImpact' },
        Metric2: { text: 'FeatureDebtIndex' },
        Metric3: { text: 'CommitterFrictionIndex' },
        Metric4: { text: 'FeatureNewCode' },
        Metric5: { text: 'SystemNewCode' },
      };
      returnDataList.type = 'table';
      returnDataList = [returnDataList];
      return returnDataList;
    }
    try {
      if (this.panel.detangle.issueSearch) {
        const issueTitle = this.templateSrv.replaceWithText('$issue_title', null);
        const shouldApplyIssueSearch = issueTitle !== '' && issueTitle !== '-' && issueTitle !== '$issue_title';
        if (shouldApplyIssueSearch) {
          const acceptedIssues = issueIdTitleChecker(dataList[this.panel.detangle.issueSearchQueryOrder], issueTitle);
          dataList.splice(this.panel.detangle.issueSearchQueryOrder, 1);
          for (let i = 0; i < dataList.length; i++) {
            const tempColumns = dataList[i].columns;
            const issueIdIndex = _.findIndex(tempColumns, { text: '@issueId' });
            dataList[i].rows = dataList[i].rows.filter(item => acceptedIssues[item[issueIdIndex]]);
          }
        } else {
          dataList.splice(this.panel.detangle.issueSearchQueryOrder, 1);
        }
      }
      if (this.panel.detangle.ratio) {
        dataList = this.detangleSrvNew.adaptDataList(dataList, this.panel.detangle, DetangleServiceMode.Table);
        dataList = this.calculateRatio(dataList);
      }

      if (this.panel.detangle.custom && this.panel.detangle.customMetric === 'NormalizedConstantVSConstant') {
        const yearMonthColumn = '@yearMonth';
        dataList = this.detangleSrvNew.adaptDataList(dataList, this.panel.detangle, DetangleServiceMode.Table);
        const yearMonthColumnIndex = _.findIndex(dataList[0].columns, { text: yearMonthColumn });
        const yearMonthList = _.uniq(dataList[0].rows.map(item => item[yearMonthColumnIndex])).sort();
        const numberOfPeriodsToConsider = this.panel.detangle.periodsToConsider;
        const latestPeriodIndex = yearMonthList.findIndex(item => item === this.panel.detangle.latestTimePeriod);
        const startingPeriodIndex =
          latestPeriodIndex > numberOfPeriodsToConsider ? latestPeriodIndex - numberOfPeriodsToConsider : 0;
        const startingPeriod = yearMonthList[startingPeriodIndex];
        const previousStartingPeriodIndex = startingPeriodIndex > 0 ? startingPeriodIndex - 1 : 0;
        const previousStartingPeriod = yearMonthList[previousStartingPeriodIndex];
        const lastPeriodData = dataListAggregator(_.cloneDeep(dataList[0]), (item, columnIndex) => {
          return item[columnIndex] >= startingPeriod && item[columnIndex] <= this.panel.detangle.latestTimePeriod;
        });
        const previousTimePeriodData = dataListAggregator(_.cloneDeep(dataList[0]), (item, columnIndex) => {
          return (
            item[columnIndex] >= previousStartingPeriod && item[columnIndex] <= this.panel.detangle.previousTimePeriod
          );
        });

        const latestdLOCList = dataListAggregator(_.cloneDeep(dataList[1]), (item, columnIndex) => {
          return item[columnIndex] >= startingPeriod && item[columnIndex] <= this.panel.detangle.latestTimePeriod;
        });
        const previousdLOCList = dataListAggregator(_.cloneDeep(dataList[1]), (item, columnIndex) => {
          return (
            item[columnIndex] >= previousStartingPeriod && item[columnIndex] <= this.panel.detangle.previousTimePeriod
          );
        });
        const pathList = {};
        for (let i = 0; i < latestdLOCList.rows.length; i++) {
          const tempLOCObject = latestdLOCList.rows[i];
          pathList[tempLOCObject[0]] = {
            constant: tempLOCObject[1],
            constantPrevious: 0,
            data: 0,
            dataPrevious: 0,
          };
        }

        for (let i = 0; i < previousdLOCList.rows.length; i++) {
          const tempLOCObject = previousdLOCList.rows[i];
          const tempPathObject = pathList[tempLOCObject[0]];
          if (tempPathObject) {
            tempPathObject.constantPrevious = tempLOCObject[1];
          }
        }

        for (let i = 0; i < lastPeriodData.rows.length; i++) {
          const tempLOCObject = lastPeriodData.rows[i];
          const tempPathObject = pathList[tempLOCObject[0]];
          if (tempPathObject) {
            tempPathObject.data = tempLOCObject[1];
          }
        }

        for (let i = 0; i < previousTimePeriodData.rows.length; i++) {
          const tempLOCObject = previousTimePeriodData.rows[i];
          const tempPathObject = pathList[tempLOCObject[0]];
          if (tempPathObject) {
            tempPathObject.dataPrevious = tempLOCObject[1];
          }
        }

        const generatedRowList = [];
        Object.keys(pathList).map(item => {
          const tempValueHash = pathList[item];
          const normalizedValue = tempValueHash.data / Math.max(1, Math.round((tempValueHash.constant / 1000) * 2) / 2);
          const normalizedValuePrevious =
            tempValueHash.dataPrevious / Math.max(1, Math.round((tempValueHash.constantPrevious / 1000) * 2) / 2);
          const increase =
            Math.floor(((normalizedValue - normalizedValuePrevious) / normalizedValuePrevious) * 100) || 0;
          const tempObject = {
            0: item,
            1: normalizedValue || 0,
            2: increase || 0,
            3: tempValueHash.constant || 0,
          };
          generatedRowList.push(tempObject);
        });

        let returnDataList: any = {};
        returnDataList.rows = generatedRowList;
        returnDataList.columns = [
          { text: '@path', filterable: true },
          { text: 'NormalizedConstant' },
          { text: 'Increase' },
          { text: 'Constant' },
        ];
        returnDataList.columnMap = {
          '@path': { text: '@path', filterable: true },
          Metric1: { text: 'NormalizedConstant' },
          Metric4: { text: 'Increase' },
          Metric6: { text: 'Constant' },
        };
        returnDataList.type = 'table';
        returnDataList = [returnDataList];
        dataList = returnDataList;
      }

      if (this.panel.detangle.custom && this.panel.detangle.customMetric === 'NormalizedDebtVSConstant') {
        const debtConfig = _.clone(this.panel.detangle);
        debtConfig.periodicDebtCalculation = true;
        debtConfig.isNormalized = true;
        const tableDecorator = this.detangleSrvNew.adaptDataList(dataList, debtConfig, DetangleServiceMode.Table);
        dataList = tableDecorator.getAsQueryResult();
      }
      if (this.panel.detangle.custom && this.panel.detangle.customMetric === 'DebtVSConstant') {
        const debtConfig = _.clone(this.panel.detangle);
        debtConfig.periodicDebtCalculation = true;
        const tableDecorator = this.detangleSrvNew.adaptDataList(dataList, debtConfig, DetangleServiceMode.Table);
        dataList = tableDecorator.getAsQueryResult();
      }
      if (this.panel.detangle.custom && this.panel.detangle.customMetric === 'Combined') {
        const bugIndexDecorator = this.detangleSrvNew.adaptDataList(
          [dataList[0]],
          this.panel.detangle,
          DetangleServiceMode.Table
        );

        const featureNewCode = this.detangleSrvNew.adaptDataList(
          [dataList[5]],
          this.panel.detangle,
          DetangleServiceMode.Table
        );
        const systemNewCode = this.detangleSrvNew.adaptDataList(
          [dataList[6]],
          this.panel.detangle,
          DetangleServiceMode.Table
        );
        const newConfig = _.clone(this.panel.detangle);
        newConfig.periodicDebtCalculation = true;

        const couplingTableDecorator = this.detangleSrvNew.adaptDataList(
          [dataList[1], dataList[2]],
          newConfig,
          DetangleServiceMode.Table
        );
        const defectImpactDecorator = this.detangleSrvNew.adaptDataList(
          [dataList[3], dataList[2]],
          newConfig,
          DetangleServiceMode.Table
        );
        const developerCouplingTableDecorator = this.detangleSrvNew.adaptDataList(
          [dataList[4]],
          newConfig,
          DetangleServiceMode.Table
        );
        const newDataList = [];
        newDataList.push(defectImpactDecorator.getTable());
        newDataList.push(bugIndexDecorator[0]);
        newDataList.push(featureNewCode[0]);
        newDataList.push(systemNewCode[0]);
        newDataList.push(couplingTableDecorator.getTable());
        newDataList.push(developerCouplingTableDecorator.getTable());
        dataList = combineResults(newDataList);
      }

      if (this.panel.detangle.custom && this.panel.detangle.customMetric === 'EffortEstimation') {
        const newConfig = _.clone(this.panel.detangle);
        newConfig.estimationCalculation = true;
        const tableDecorator = this.detangleSrvNew.adaptDataList(dataList, newConfig, DetangleServiceMode.Table);
        dataList = tableDecorator.getAsQueryResult();
      }

      if (this.panel.detangle.custom && this.panel.detangle.customMetric === 'FeatureDebtVSCouplingVSCohesion') {
        const newConfig = _.clone(this.panel.detangle);
        newConfig.coupling = true;
        const couplingResult = this.detangleSrvNew.adaptDataList(dataList, newConfig, DetangleServiceMode.Table);
        dataList = couplingResult.getAsQueryResult();
      }

      if (this.panel.detangle.custom && this.panel.detangle.customMetric === 'EffortIndex') {
        const newConfig = _.clone(this.panel.detangle);
        newConfig.indexCalculation = true;
        newConfig.applyFolderLevel = true;
        const tableDecorator = this.detangleSrvNew.adaptDataList(dataList, newConfig, DetangleServiceMode.Table);
        dataList = tableDecorator.getAsQueryResult();
      }
      if (this.panel.detangle.custom && this.panel.detangle.customMetric === 'Combination') {
        const newConfig = _.clone(this.panel.detangle);
        newConfig.combinationCalculation = true;
        const tableDecorator = this.detangleSrvNew.adaptDataList(dataList, newConfig, DetangleServiceMode.Table);
        dataList = tableDecorator.getAsQueryResult();
      }
      if (this.panel.detangle.custom && this.panel.detangle.customMetric === 'Cost') {
        const newConfig = _.clone(this.panel.detangle);
        newConfig.costCalculation = true;
        const tableDecorator = this.detangleSrvNew.adaptDataList(dataList, newConfig, DetangleServiceMode.Table);
        dataList = tableDecorator.getAsQueryResult();
      }
      if (this.panel.detangle.softFilter) {
        const newConfig = _.clone(this.panel.detangle);
        const tableDecorator = this.detangleSrvNew.adaptDataList(dataList, newConfig, DetangleServiceMode.Table);
        dataList = tableDecorator.getAsQueryResult();
      }
      if (this.panel.detangle.custom && this.panel.detangle.customMetric === 'KnowledgeDistribution') {
        const newConfig = _.clone(this.panel.detangle);
        newConfig.knowledgeDistribution = true;
        const tableDecorator = this.detangleSrvNew.adaptDataList(dataList, newConfig, DetangleServiceMode.Table);
        dataList = tableDecorator.getAsQueryResult();
      }
      if (this.panel.detangle.custom && this.panel.detangle.customMetric === 'Ratio') {
        const newConfig = _.clone(this.panel.detangle);
        newConfig.indexCalculation = true;
        newConfig.normalizerMultiplier = 1;
        const tableDecorator = this.detangleSrvNew.adaptDataList(dataList, newConfig, DetangleServiceMode.Table);
        dataList = tableDecorator.getAsQueryResult();
      }
      this.remainingTries = 2;
    } catch (err) {
      if (this.remainingTries > 0) {
        this.refresh();
      }
      this.remainingTries -= 1;
    }

    /**
     * @detangleEdit end
     * @author Ural
     */
    this.dataRaw = dataList;
    this.pageIndex = 0;

    // automatically correct transform mode based on data
    if (this.dataRaw && this.dataRaw.length) {
      if (isTableData(this.dataRaw[0])) {
        this.panel.transform = 'table';
      } else {
        if (this.dataRaw[0].type === 'docs') {
          this.panel.transform = 'json';
        } else {
          if (this.panel.transform === 'table' || this.panel.transform === 'json') {
            this.panel.transform = 'timeseries_to_rows';
          }
        }
      }
    }

    this.render();
  }

  render() {
    this.table = transformDataToTable(this.dataRaw, this.panel);
    this.table.sort(this.panel.sort);

    this.renderer = new TableRenderer(
      this.panel,
      this.table,
      this.dashboard.isTimezoneUtc(),
      this.$sanitize,
      this.templateSrv,
      config.theme.type
    );

    return super.render(this.table);
  }

  toggleColumnSort(col: any, colIndex: any) {
    // remove sort flag from current column
    if (this.table.columns[this.panel.sort.col]) {
      this.table.columns[this.panel.sort.col].sort = false;
    }

    if (this.panel.sort.col === colIndex) {
      if (this.panel.sort.desc) {
        this.panel.sort.desc = false;
      } else {
        this.panel.sort.col = null;
      }
    } else {
      this.panel.sort.col = colIndex;
      this.panel.sort.desc = true;
    }
    this.render();
  }

  exportCsv() {
    const scope = this.$scope.$new(true);
    scope.tableData = this.renderer.render_values();
    scope.panel = 'table';
    this.publishAppEvent('show-modal', {
      templateHtml: '<export-data-modal panel="panel" data="tableData"></export-data-modal>',
      scope,
      modalClass: 'modal--narrow',
    });
  }

  link(scope: any, elem: JQuery, attrs: any, ctrl: TablePanelCtrl) {
    let data: any;
    const panel = ctrl.panel;
    let pageCount = 0;

    function getTableHeight() {
      let panelHeight = ctrl.height;

      if (pageCount > 1) {
        panelHeight -= 26;
      }

      return panelHeight - 31 + 'px';
    }

    function appendTableRows(tbodyElem: JQuery) {
      ctrl.renderer.setTable(data);
      tbodyElem.empty();
      tbodyElem.html(ctrl.renderer.render(ctrl.pageIndex));
    }

    function switchPage(e: any) {
      const el = $(e.currentTarget);
      ctrl.pageIndex = parseInt(el.text(), 10) - 1;
      renderPanel();
    }

    function appendPaginationControls(footerElem: JQuery) {
      footerElem.empty();

      const pageSize = panel.pageSize || 100;
      pageCount = Math.ceil(data.rows.length / pageSize);
      if (pageCount === 1) {
        return;
      }

      const startPage = Math.max(ctrl.pageIndex - 3, 0);
      const endPage = Math.min(pageCount, startPage + 9);

      const paginationList = $('<ul></ul>');

      for (let i = startPage; i < endPage; i++) {
        const activeClass = i === ctrl.pageIndex ? 'active' : '';
        const pageLinkElem = $(
          '<li><a class="table-panel-page-link pointer ' + activeClass + '">' + (i + 1) + '</a></li>'
        );
        paginationList.append(pageLinkElem);
      }

      footerElem.append(paginationList);
    }

    function renderPanel() {
      const panelElem = elem.parents('.panel-content');
      const rootElem = elem.find('.table-panel-scroll');
      const tbodyElem = elem.find('tbody');
      const footerElem = elem.find('.table-panel-footer');

      elem.css({ 'font-size': panel.fontSize });
      panelElem.addClass('table-panel-content');

      appendTableRows(tbodyElem);
      appendPaginationControls(footerElem);

      rootElem.css({ 'max-height': panel.scroll ? getTableHeight() : '' });
    }

    // hook up link tooltips
    elem.tooltip({
      selector: '[data-link-tooltip]',
    });

    function addFilterClicked(e) {
      const filterData = $(e.currentTarget).data();
      const options = {
        name: 'file_include',
        value: data.rows[filterData.row][filterData.column],
      };

      ctrl.variableSrv.setCustomVariable(options);
    }

    function previewLongText(e) {
      const filterData = $(e.currentTarget).data();
      const longText = data.rows[filterData.row][filterData.column];
      let titleText = '';
      if (data.columns.findIndex(x => x.text === '@issueId') > -1) {
        const issueIdColumnIdx = data.columns.findIndex(x => x.text === '@issueId');
        console.log(issueIdColumnIdx);
        const issueIdText = data.rows[filterData.row][issueIdColumnIdx];
        titleText = `"${issueIdText}" - `;
      }
      titleText += data.columns[filterData.column].title;
      const modelObj = {
        title: titleText,
        description: longText,
      };
      appEvents.emit('show-modal', {
        src: 'public/app/features/plugins/partials/long_text.html',
        model: modelObj,
      });
    }

    elem.on('click', '.table-panel-page-link', switchPage);
    elem.on('click', '.table-panel-filter-link', addFilterClicked);
    elem.on('click', '.data-long-text-link', previewLongText);

    const unbindDestroy = scope.$on('$destroy', () => {
      elem.off('click', '.table-panel-page-link');
      elem.off('click', '.table-panel-filter-link');
      elem.off('click', '.data-long-text-link');
      unbindDestroy();
    });

    ctrl.events.on('render', (renderData: any) => {
      data = renderData || data;
      if (data) {
        renderPanel();
      }
      ctrl.renderingCompleted();
    });
  }
}

export { TablePanelCtrl, TablePanelCtrl as PanelCtrl };
