import './graph';
import './series_overrides_ctrl';
import './thresholds_form';
import './time_regions_form';

import template from './template';
import _ from 'lodash';

import { MetricsPanelCtrl } from 'app/plugins/sdk';
import { DataProcessor } from './data_processor';
import { axesEditorComponent } from './axes_editor';
import config from 'app/core/config';
import TimeSeries from 'app/core/time_series2';
import { DataFrame, DataLink, DateTimeInput, toLegacyResponseData, toDataFrame } from '@grafana/data';
import { getColorFromHexRgbOrName, LegacyResponseData, VariableSuggestion } from '@grafana/ui';
// import { getProcessedDataFrame } from 'app/features/dashboard/state/PanelQueryState';
import { PanelQueryRunnerFormat } from 'app/features/dashboard/state/PanelQueryRunner';
import { GraphContextMenuCtrl } from './GraphContextMenuCtrl';
import { getDataLinksVariableSuggestions } from 'app/features/panel/panellinks/link_srv';

import { auto } from 'angular';
import { AnnotationsSrv } from 'app/features/annotations/all';
// tslint:disable-next-line:import-blacklist
import moment from 'moment';
import { DetangleServiceMode } from '../../../core/services/detangle/detangle_srv';
import { issueIdTitleChecker } from 'app/core/services/detangle/utils';
// import { Data } from 'slate';

class GraphCtrl extends MetricsPanelCtrl {
  static template = template;

  renderError: boolean;
  hiddenSeries: any = {};
  seriesList: TimeSeries[] = [];
  dataList: DataFrame[] = [];
  annotations: any = [];
  alertState: any;

  annotationsPromise: any;
  dataWarning: any;
  colors: any = [];
  subTabIndex: number;
  processor: DataProcessor;
  contextMenuCtrl: GraphContextMenuCtrl;
  linkVariableSuggestions: VariableSuggestion[] = getDataLinksVariableSuggestions();

  sortingOrder: any = [];
  couplingMetrics: any = [];
  targetSelections: any = [];
  intervals: any = [];
  cohesionCalculationMethods: any = [];
  customMetrics: any = [];
  normalizationMethods: any = [];

  panelDefaults: any = {
    // datasource name, null = default datasource
    datasource: null,
    // sets client side (flot) or native graphite png renderer (png)
    renderer: 'flot',
    yaxes: [
      {
        label: null,
        show: true,
        logBase: 1,
        min: null,
        max: null,
        format: 'short',
      },
      {
        label: null,
        show: true,
        logBase: 1,
        min: null,
        max: null,
        format: 'short',
      },
    ],
    xaxis: {
      show: true,
      mode: 'time',
      name: null,
      values: [],
      buckets: null,
    },
    yaxis: {
      align: false,
      alignLevel: null,
    },
    // show/hide lines
    lines: true,
    // rainbow colors
    rainbowColors: false,
    // fill factor
    fill: 1,
    // fill factor
    fillGradient: 0,
    // line width in pixels
    linewidth: 1,
    // show/hide dashed line
    dashes: false,
    // length of a dash
    dashLength: 10,
    // length of space between two dashes
    spaceLength: 10,
    // show hide points
    points: false,
    // point radius in pixels
    pointradius: 2,
    // show hide bars
    bars: false,
    // enable/disable stacking
    stack: false,
    // stack percentage mode
    percentage: false,
    /**
     * @detangleEdit start
     * @author Ural
     */
    // Detangle Options
    detangle: {
      coupling: false,
      diamondPattern: false,
      isNormalized: false,
      knowledgeDistribution: false,
      aggregateFiles: false,
      disableTimePeriod: false,
      referenceCalculation: false,
      developerCount: '',
      showPM: false,
      applyDifferentCommonCondition: false,
      differentCommonConditionThreshold: 0.75,
      sortingOrder: 'desc',
      limit: null,
      metric: 'coupling',
      sourceType: '$issue_type',
      targetType: '$target_issue_type',
      sourceTypeData: '',
      targetTypeData: '',
      author: '$author',
      authorData: '',
      yearData: '',
      target: 'issue',
      minIssuesPerFile: null,
      minIssuesData: '',
      minFilesPerIssue: null,
      minFilesData: '',
      issueTitle: '$issue_title',
      issueTitleData: '',
      fileExcludeFilter: '$file_exclude',
      fileExcludeFilterData: '',
      metricRange: '$metric_range',
      metricRangeData: '',
      fileGroup: '$file_group',
      fileGroupData: '',
      percentile: false,
      percentileInterval: 'yearly',
      percentileRanks: '25,50,75',
      interval: 'yearly',
      isInterval: false,
      isAggregated: false,
      cohesionCalculationMethod: 'standard',
      isAuthorDashboard: false,
      subtraction: false,
      aggregate: false,
      customMetric: '',
      normalizationMethod: 'Method1',
      system: false,
      ratio: false,
      issueSearch: false,
      effortNormalization: false,
      multiProject: false,
    },
    /**
     * @detangleEdit end
     * @author Ural
     */
    // legend options
    legend: {
      show: true, // disable/enable legend
      values: false, // disable/enable legend values
      min: false,
      max: false,
      current: false,
      total: false,
      avg: false,
    },
    // how null points should be handled
    nullPointMode: 'null',
    // staircase line mode
    steppedLine: false,
    // tooltip options
    tooltip: {
      value_type: 'individual',
      shared: true,
      sort: 0,
      hideZero: true,
    },
    // time overrides
    timeFrom: null,
    timeShift: null,
    // metric queries
    targets: [{}],
    // series color overrides
    aliasColors: {},
    // other style overrides
    seriesOverrides: [],
    thresholds: [],
    timeRegions: [],
    options: {
      dataLinks: [],
    },
  };

  /** @ngInject */
  constructor(
    $scope: any,
    $injector: auto.IInjectorService,
    private annotationsSrv: AnnotationsSrv,
    private detangleSrvNew
  ) {
    super($scope, $injector);

    _.defaults(this.panel, this.panelDefaults);
    _.defaults(this.panel.tooltip, this.panelDefaults.tooltip);
    _.defaults(this.panel.legend, this.panelDefaults.legend);
    _.defaults(this.panel.xaxis, this.panelDefaults.xaxis);
    _.defaults(this.panel.options, this.panelDefaults.options);

    this.dataFormat = PanelQueryRunnerFormat.series;
    this.processor = new DataProcessor(this.panel);
    this.contextMenuCtrl = new GraphContextMenuCtrl($scope);

    this.events.on('render', this.onRender.bind(this));
    this.events.on('data-frames-received', this.onDataFramesReceived.bind(this));
    this.events.on('data-received', this.onDataReceived.bind(this));
    this.events.on('data-error', this.onDataError.bind(this));
    this.events.on('data-snapshot-load', this.onDataSnapshotLoad.bind(this));
    this.events.on('init-edit-mode', this.onInitEditMode.bind(this));
    this.events.on('init-panel-actions', this.onInitPanelActions.bind(this));

    this.onDataLinksChange = this.onDataLinksChange.bind(this);

    /**
     * @detangleEdit start
     * @author Ural
     */
    this.sortingOrder = [{ text: 'Ascending', value: 'asc' }, { text: 'Descending', value: 'desc' }];

    this.couplingMetrics = [
      { text: 'Coupling Value', value: 'coupling' },
      { text: 'Num. of Couples', value: 'couplecounts' },
      { text: 'Cohesion Value', value: 'cohesion' },
    ];

    this.targetSelections = [{ text: 'Issues|Committers', value: 'issue' }, { text: 'Files', value: 'file' }];

    this.intervals = [{ text: 'Yearly', value: 'yearly' }];

    this.cohesionCalculationMethods = [
      {
        text: 'Standard',
        value: 'standard',
      },
      {
        text: 'Double',
        value: 'double',
      },
    ];

    this.customMetrics = [
      {
        text: 'NormBugIndex',
        value: 'NormBugIndex',
      },
      {
        text: 'Debt',
        value: 'Debt',
      },
      {
        text: 'BugIndex',
        value: 'BugIndex',
      },
      {
        text: 'Ratio',
        value: 'Ratio',
      },
      {
        text: 'Cost',
        value: 'Cost',
      },
    ];

    this.normalizationMethods = [
      {
        text: 'Method1',
        value: 'Method1',
      },
      {
        text: 'Method2',
        value: 'Method2',
      },
    ];
    /**
     * @detangleEdit end
     * @author Ural
     */
  }

  onInitEditMode() {
    this.addEditorTab('Display options', 'public/app/plugins/panel/graph/tab_display.html');
    this.addEditorTab('Axes', axesEditorComponent);
    this.addEditorTab('Legend', 'public/app/plugins/panel/graph/tab_legend.html');
    this.addEditorTab('Thresholds & Time Regions', 'public/app/plugins/panel/graph/tab_thresholds_time_regions.html');
    this.addEditorTab('Data link', 'public/app/plugins/panel/graph/tab_drilldown_links.html');
    this.addEditorTab('Detangle', 'public/app/plugins/panel/graph/detangle.html');

    this.subTabIndex = 0;
  }

  onInitPanelActions(actions: any[]) {
    actions.push({ text: 'Export CSV', click: 'ctrl.exportCsv()' });
    actions.push({ text: 'Toggle legend', click: 'ctrl.toggleLegend()', shortcut: 'p l' });
  }

  issueQueries(datasource: any) {
    this.annotationsPromise = this.annotationsSrv.getAnnotations({
      dashboard: this.dashboard,
      panel: this.panel,
      range: this.range,
    });

    /* Wait for annotationSrv requests to get datasources to
     * resolve before issuing queries. This allows the annotations
     * service to fire annotations queries before graph queries
     * (but not wait for completion). This resolves
     * issue 11806.
     */
    return this.annotationsSrv.datasourcePromises.then((r: any) => {
      return super.issueQueries(datasource);
    });
  }

  zoomOut(evt: any) {
    this.publishAppEvent('zoom-out', 2);
  }

  onDataSnapshotLoad(snapshotData: any) {
    this.annotationsPromise = this.annotationsSrv.getAnnotations({
      dashboard: this.dashboard,
      panel: this.panel,
      range: this.range,
    });
    this.onDataReceived(snapshotData);
  }

  onDataError(err: any) {
    this.seriesList = [];
    this.annotations = [];
    this.render([]);
  }

  // This should only be called from the snapshot callback
  onDataReceived(dataList: LegacyResponseData[]) {
    //this.onDataFramesReceived(getProcessedDataFrame(dataList));
  }

  // Directly support DataFrame skipping event callbacks
  onDataFramesReceived(data: DataFrame[]) {
    /**
     * @detangleEdit start
     * @author Ural
     */
    if (this.panel.detangle.issueSearch) {
      let legacyDataList = this.toLegacyResponseDataList(data);
      const issueTitle = this.templateSrv.replaceWithText('$issue_title', null);
      const shouldApplyIssueSearch = issueTitle !== '' && issueTitle !== '-' && issueTitle !== '$issue_title';
      const titleDataListIndex = data.length - 1;
      if (shouldApplyIssueSearch) {
        const acceptedIssues = issueIdTitleChecker(legacyDataList[titleDataListIndex], issueTitle);
        data.splice(titleDataListIndex, 1);
        if (this.panel.detangle.data) {
          for (let i = 0; i < legacyDataList.length; i++) {
            const tempColumns = legacyDataList[i].columns;
            const issueIdIndex = _.findIndex(tempColumns, { text: '@issueId' });
            legacyDataList[i].rows = legacyDataList[i].rows.filter(item => acceptedIssues[item[issueIdIndex]]);
          }
        } else {
          legacyDataList = legacyDataList.filter(item => acceptedIssues[item.props['@issueId']]);
        }
      } else {
        legacyDataList.splice(titleDataListIndex, 1);
      }
      if (this.panel.detangle.limit) {
        if (legacyDataList[0].rows.length > this.panel.detangle.limit) {
          legacyDataList[0].rows = legacyDataList[0].rows.slice(0, this.panel.detangle.limit);
        }
        const tempDataPoints: DataFrame[] = [];
        const valueIndex = legacyDataList[0].columns.length - 1;
        const tempTime = moment();
        for (let i = 0; i < legacyDataList[0].rows.length; i++) {
          const issue = data[0].rows[i][0] + ': ' + data[0].rows[i][1];
          tempDataPoints.push(
            toDataFrame({
              datapoints: [[legacyDataList[0].rows[i][valueIndex], tempTime]],
              field: '@metric',
              metric: 'sum',
              props: { '@issue': issue },
              target: issue,
            })
          );
        }
        data = tempDataPoints;
      }
    }
    if (this.panel.detangle.softFilter) {
      let legacyDataList = this.toLegacyResponseDataList(data);

      const fileInclusionFilter = this.templateSrv.replaceWithText('$file_include', null);
      const shouldApplyFileInclusion =
        fileInclusionFilter !== '' && fileInclusionFilter !== '-' && fileInclusionFilter !== '$file_include';
      if (shouldApplyFileInclusion) {
        const regexChecker = new RegExp(fileInclusionFilter);
        legacyDataList = legacyDataList.filter(item => regexChecker.test(item.target));
        data = legacyDataList.map(item => toDataFrame(item));
      }
    }
    if (this.panel.detangle.bucket) {
      const legacyDataList = this.toLegacyResponseDataList(data);
      if (this.panel.detangle.customMetric === 'BugIndex') {
        const newConfig = _.clone(this.panel.detangle);
        newConfig.indexCalculation = true;
        data = this.detangleSrvNew.adaptDataList(legacyDataList, newConfig, DetangleServiceMode.TimeSeries);
      }
      if (this.panel.detangle.customMetric === 'Debt') {
        const newConfig = _.clone(this.panel.detangle);
        newConfig.periodicDebtCalculation = true;
        const newDataList = this.detangleSrvNew.adaptDataList(
          legacyDataList,
          newConfig,
          DetangleServiceMode.TimeSeries
        );
        data = newDataList;
      }
      if (this.panel.detangle.customMetric === 'Ratio') {
        const newConfig = _.clone(this.panel.detangle);
        newConfig.indexCalculation = true;
        newConfig.normalizerMultiplier = 1;
        data = this.detangleSrvNew.adaptDataList(legacyDataList, newConfig, DetangleServiceMode.TimeSeries);
      }
      if (this.panel.detangle.customMetric === 'Cost') {
        const newConfig = _.clone(this.panel.detangle);
        newConfig.costCalculation = true;
        data = this.detangleSrvNew.adaptDataList(legacyDataList, newConfig, DetangleServiceMode.TimeSeries);
      }
    }
    if (this.panel.detangle.effortNormalization) {
      const legacyDataList = this.toLegacyResponseDataList(data);
      const newConfig = _.clone(this.panel.detangle);
      newConfig.indexCalculation = true;
      newConfig.applyFolderLevel = true;
      data = this.detangleSrvNew.adaptDataList(legacyDataList, newConfig, DetangleServiceMode.TimeSeries);
    }
    if (this.panel.detangle.knowledgeDistribution) {
      const legacyDataList = this.toLegacyResponseDataList(data);
      const newConfig = _.clone(this.panel.detangle);
      newConfig.knowledgeDistribution = true;
      data = this.detangleSrvNew.adaptDataList(legacyDataList, newConfig, DetangleServiceMode.TimeSeries);
    }
    /**
     * @detangleEdit end
     * @author Ural
     */
    this.dataList = data;
    this.seriesList = this.processor.getSeriesList({
      dataList: this.dataList,
      range: this.range,
    });

    this.dataWarning = null;
    const datapointsCount = this.seriesList.reduce((prev, series) => {
      return prev + series.datapoints.length;
    }, 0);

    if (datapointsCount === 0) {
      this.dataWarning = {
        title: 'No data points',
        tip: 'No datapoints returned from data query',
      };
    } else {
      for (const series of this.seriesList) {
        if (series.isOutsideRange) {
          this.dataWarning = {
            title: 'Data points outside time range',
            tip: 'Can be caused by timezone mismatch or missing time filter in query',
          };
          break;
        }
      }
    }

    this.annotationsPromise.then(
      (result: { alertState: any; annotations: any }) => {
        this.loading = false;
        this.alertState = result.alertState;
        this.annotations = result.annotations;
        this.render(this.seriesList);
      },
      () => {
        this.loading = false;
        this.render(this.seriesList);
      }
    );
  }

  toLegacyResponseDataList(data: DataFrame[]): any[] {
    const legacyDataList: any[] = [];
    for (let i = 0; i < data.length; i++) {
      const v = data[i];
      if (v !== null) {
        legacyDataList.push(toLegacyResponseData(data[i]));
      }
    }
    return legacyDataList;
  }

  groupByColumnIndex(dataListObj, columnName = '@yearMonth') {
    const columnIndex = _.findIndex(dataListObj.columns, { text: columnName });
    const pathIndex = _.findIndex(dataListObj.columns, { text: '@path' });
    const valueIndex = dataListObj.columns.length - 1;
    const helper = {
      groupedLists: {},
      pathList: {},
      pathListArray: [],
    };

    for (let i = dataListObj.rows.length - 1; i >= 0; i--) {
      const tempObj = dataListObj.rows[i];
      if (!helper.groupedLists[tempObj[columnIndex]]) {
        helper.groupedLists[tempObj[columnIndex]] = {};
      }
      if (!helper.pathList[tempObj[pathIndex]]) {
        helper.pathList[tempObj[pathIndex]] = true;
      }
      helper.groupedLists[tempObj[columnIndex]][tempObj[pathIndex]] = tempObj[valueIndex];
    }
    helper.pathListArray = Object.keys(helper.pathList);
    return helper;
  }

  onRender() {
    if (!this.seriesList) {
      return;
    }

    for (const series of this.seriesList) {
      series.applySeriesOverrides(this.panel.seriesOverrides);

      if (series.unit) {
        this.panel.yaxes[series.yaxis - 1].format = series.unit;
      }
    }
  }

  onColorChange = (series: any, color: string) => {
    series.setColor(getColorFromHexRgbOrName(color, config.theme.type));
    this.panel.aliasColors[series.alias] = color;
    this.render();
  };

  onToggleSeries = (hiddenSeries: any) => {
    this.hiddenSeries = hiddenSeries;
    this.render();
  };

  onToggleSort = (sortBy: any, sortDesc: any) => {
    this.panel.legend.sort = sortBy;
    this.panel.legend.sortDesc = sortDesc;
    this.render();
  };

  onToggleAxis = (info: { alias: any; yaxis: any }) => {
    let override: any = _.find(this.panel.seriesOverrides, { alias: info.alias });
    if (!override) {
      override = { alias: info.alias };
      this.panel.seriesOverrides.push(override);
    }
    override.yaxis = info.yaxis;
    this.render();
  };

  onDataLinksChange(dataLinks: DataLink[]) {
    this.panel.updateOptions({
      ...this.panel.options,
      dataLinks,
    });
  }

  addSeriesOverride(override: any) {
    this.panel.seriesOverrides.push(override || {});
  }

  removeSeriesOverride(override: any) {
    this.panel.seriesOverrides = _.without(this.panel.seriesOverrides, override);
    this.render();
  }

  toggleLegend() {
    this.panel.legend.show = !this.panel.legend.show;
    this.render();
  }

  legendValuesOptionChanged() {
    const legend = this.panel.legend;
    legend.values = legend.min || legend.max || legend.avg || legend.current || legend.total;
    this.render();
  }

  exportCsv() {
    const scope = this.$scope.$new(true);
    scope.seriesList = this.seriesList;
    this.publishAppEvent('show-modal', {
      templateHtml: '<export-data-modal data="seriesList"></export-data-modal>',
      scope,
      modalClass: 'modal--narrow',
    });
  }

  onContextMenuClose = () => {
    this.contextMenuCtrl.toggleMenu();
  };

  formatDate = (date: DateTimeInput, format?: string) => {
    return this.dashboard.formatDate.apply(this.dashboard, [date, format]);
  };
}

export { GraphCtrl, GraphCtrl as PanelCtrl };
