import { CalculationMethod } from '../constants';
import { DataHandler } from '../data_handler';
import { DetangleConfig } from '../detangle_config';
import { IndexCalculator } from './index_calculator';
import { DetangleCalculator } from './detangle_calculator';
import { DebtCalculator } from './debt_calculator';
import { PeriodicDebtCalculator } from './periodic_debt_calculator';
import { BaseCalculator } from './base_calculator';
import { EstimationCalculator } from './estimation_calculator';
import { TrendCalculator } from './trend_calculator';
import { KnowledgeCalculator } from './knowledge_calculator';
import { CombinationCalculator } from './combination_calculator';
import { CostCalculator } from './cost_calculator';

/**
 * Abstract factory method that returns calculator class object
 * according to the specified calculation method.
 *
 * @param {DataHandler[]} dataHandlerList
 * @param {DetangleConfig} config
 * @returns {DetangleCalculator}
 */
export const createCalculator = (dataHandlerList: DataHandler[], config: DetangleConfig): DetangleCalculator => {
  switch (config.calculationMethod) {
    case CalculationMethod.IndexCalculation:
      return new IndexCalculator(dataHandlerList, config);
    case CalculationMethod.DebtCalculation:
      return new DebtCalculator(dataHandlerList, config);
    case CalculationMethod.PeriodicDebtCalculation:
      return new PeriodicDebtCalculator(dataHandlerList, config);
    case CalculationMethod.EstimationCalculation:
      return new EstimationCalculator(dataHandlerList, config);
    case CalculationMethod.TrendCalculation:
      return new TrendCalculator(dataHandlerList, config);
    case CalculationMethod.KnowledgeCalculation:
      return new KnowledgeCalculator(dataHandlerList, config);
    case CalculationMethod.CombinationCalculation:
      return new CombinationCalculator(dataHandlerList, config);
    case CalculationMethod.CostCalculation:
      return new CostCalculator(dataHandlerList, config);
    default:
      return new BaseCalculator(dataHandlerList, config);
  }
};
