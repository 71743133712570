/**
 * Actual column names in elastic search indicies.
 *
 * @export
 * @enum {number}
 */
export enum DbColumn {
  IssueIdColumn = '@issueId',
  PathColumn = '@path',
  ParentDirectoryColumn = '@parentDirectory',
  TitleColumn = '@issueTitle',
  IssueTypeColumn = '@issueType',
  AuthorColumn = '@author',
  IssueOwnerColumn = '@issueOwner',
  YearColumn = '@year',
  DaysColumn = '@days',
  YearMonthColumn = '@yearMonth',
  IntervalColumn = '$interval',
}

/**
 * Templating variable names to get global filter values.
 *
 * @export
 * @enum {number}
 */
export enum FilterType {
  LatestTimePeriodFilter = '$latest_time_period',
  PreviousTimePeriodFilter = '$previous_time_period',
  MinDifferentConnectionsFilter = '$min_different_connections',
  MinCommonConnectionsFilter = '$min_common_connections',
  MinDifferentCommittersFilter = '$min_different_committers',
  MinCommonCommittersFilter = '$min_common_committers',
  PathDifferenceLevelFilter = '$path_difference_level',
  NumberOfPeriodsToConsiderFilter = '$periods_to_consider',
  DebtCriticalPercentageFilter = '$debt_critical',
  DebtWarningPercentageFilter = '$debt_warning',
  DetangleRatioFilter = '$detangle_ratio',
  FolderLevelFilter = '$folder_level',
  MetricRangeFilter = '$metric_range',
  FileInclusionFilter = '$file_include',
  FileExclusionFilter = '$file_exclude',
  IssueFilter = '$issue_title',
  CouplingPercentage = '$coupling_percentage',
  TimeGranularity = '$time_granularity',
}

/**
 * Available calculations.
 *
 * @export
 * @enum {number}
 */
export enum CalculationMethod {
  BaseCalculation = 'Base',
  DebtCalculation = 'Debt',
  PeriodicDebtCalculation = 'PeriodicDebt',
  IndexCalculation = 'Index',
  EstimationCalculation = 'Estimation',
  TrendCalculation = 'TrendCalculation',
  KnowledgeCalculation = 'KnowledgeCalculation',
  CombinationCalculation = 'CombinationCalculation',
  CostCalculation = 'CostCalculation',
}

/**
 * Available time granularities.
 *
 * @export
 * @enum {string}
 */
export enum TimeGranularity {
  Year = 'year',
  Quarter = 'quarter',
  Month = 'month',
}

export const NotAvailableText = 'N/A';

/**
 * Available estimation methods.
 *
 * @export
 * @enum {number}
 */
export enum EstimationMethod {
  RawEstimatedEffort = 'RawEstimatedEffort',
  AvgEstimatedEffort = 'AvgEstimatedEffort',
  LatestEstimatedEffort = 'LatestEstimatedEffort',
  CriticalLocRatio = 'CriticalLocRatio',
  WarningLocRatio = 'WarningLocRatio',
}

export enum WarningLevel {
  Critical = 'Critical',
  Warning = 'Warning',
  Regular = 'Regular',
}
