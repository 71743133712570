import { TemplateSrv } from 'app/features/templating/template_srv';
import coreModule from '../../core_module';
import { DetangleConfig } from './detangle_config';
import { DetangleFilterManager } from './detangle_filter_manager';
import { DataHandler } from './data_handler';
import { createCalculator } from './calculators/calculator_factory';

export enum DetangleServiceMode {
  TimeSeries = 'TimeSeries',
  SingleValue = 'SingleValue',
  Table = 'Table',
  SourceTargetPairs = 'SourceTargetPairs',
  RawData = 'RawData',
}
/**
 * Main service class responsible for filtering, calculating,
 * and presenting the data
 *
 * @export
 * @class DetangleSrv
 */
export class DetangleSrv {
  config: DetangleConfig;
  mode: DetangleServiceMode;
  dataHandlerList: DataHandler[];

  /** @ngInject */
  constructor(private templateSrv: TemplateSrv) {}

  /**
   * Adapts the data list returned from elasticsearch according to
   * selected calculation method and presentation type.
   *
   * @param {any[]} dataList
   * @param {*} config
   * @param {DetangleServiceMode} [mode=DetangleServiceMode.TimeSeries]
   * @returns {any}
   */
  adaptDataList = (dataList: any[], config: any, mode: DetangleServiceMode = DetangleServiceMode.TimeSeries): any => {
    this.config = new DetangleConfig(this.templateSrv, config);
    this.dataHandlerList = dataList.map(item => new DataHandler(item));
    this.mode = mode;
    const dataFilter = new DetangleFilterManager(this.dataHandlerList, this.config);
    this.dataHandlerList = dataFilter.getFilteredData();

    const calculator = createCalculator(this.dataHandlerList, this.config);
    calculator.process();
    const adaptedResult = calculator.getAdaptedResult(this.mode);
    return adaptedResult;
  };
}

coreModule.service('detangleSrvNew', DetangleSrv);
