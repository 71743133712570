import { increaseAsPercentage } from '../utils';
import { NotAvailableText } from '../constants';

/**
 * Decorator is responsible for providing data according to the needs
 * of SingleStat Plugin.
 *
 * @export
 * @class SingleValueDecorator
 */
export class SingleValueDecorator {
  private latestValue: number;
  private previousValue: number;
  private differencePercentage: number;

  /**
   * Creates an instance of SingleValueDecorator.
   *
   * @param {number} latestValue
   * @param {number} previousValue
   * @memberof SingleValueDecorator
   */
  constructor(latestValue: number, previousValue: number) {
    this.latestValue = latestValue;
    this.previousValue = previousValue;
    this.differencePercentage = increaseAsPercentage(latestValue, previousValue);
  }

  /**
   * Gets the number representation of the latest value.
   *
   * @returns {number}
   */
  getLatestValue = (): number => {
    return this.latestValue;
  };

  /**
   * Gets the number representation of the previous value.
   *
   * @returns {number}
   */
  getPreviousValue = (): number => {
    return this.previousValue;
  };

  /**
   * Gets the string representation of the latest value, with 2 decimals.
   *
   * @returns {string}
   */
  getLatestValueString = (): string => {
    return this.latestValue.toFixed(2).replace(/\.0+$/, '');
  };

  /**
   * Gets the string representation of the previous value, with 2 decimals.
   *
   * @returns {string}
   */
  getPreviousValueString = (): string => {
    return this.previousValue.toFixed(2).replace(/\.0+$/, '');
  };

  /**
   * Gets the number representation of the percentage difference.
   *
   * @returns {number}
   */
  getDifferencePercentage = (): number => {
    return this.differencePercentage;
  };

  /**
   * Gets the string representation of the percentage difference,
   *  with sign.
   *
   * @returns {string}
   */
  getDifferencePercentageString = (): string => {
    if (!isFinite(this.differencePercentage)) {
      return NotAvailableText;
    }
    if (this.differencePercentage > 0) {
      return '+' + this.differencePercentage.toString();
    }
    return this.differencePercentage.toString();
  };
}
