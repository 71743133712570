import { DetangleCalculator } from './detangle_calculator';
import { TableDecorator } from '../decorators/table_decorator';
import { isFilterValid } from '../utils';
import { FilterType } from '../constants';

export class CombinationCalculator extends DetangleCalculator {
  referenceColumn: string;
  showIssueTitles: boolean;
  showIssueTypes: boolean;

  process = (): void => {
    this.referenceColumn = 'path';
    const timePeriodsToConsider = this.config.disableTimePeriod ? 0 : this.config.periodsToConsider;
    if (this.dataHandlerList[0].refColumnIndex > -1) {
      this.referenceColumn = 'ref';
      if (this.dataHandlerList[0].issueTitleColumnIndex > -1) {
        this.showIssueTitles = true;
      }
      if (this.dataHandlerList[0].issueTypeColumnIndex > -1) {
        this.showIssueTypes = true;
      }
    }
    if (this.config.applyFolderLevel) {
      this.dataHandlerList.forEach(dataHandler => {
        dataHandler.setFoldersWithFolderLevel(this.config.folderLevel);
      });
    }
    this.dataHandlerList.forEach(dataHandler => {
      if (dataHandler.yearMonthColumnIndex === -1) {
        return;
      }
      const latestTimePeriodAvailable = dataHandler
        .getPeriodList()
        .slice(-1)
        .pop();
      const aggregatedRows = dataHandler.getAggregatedRowListWithTimePeriod(
        latestTimePeriodAvailable,
        timePeriodsToConsider
      );
      dataHandler.updateRowList(aggregatedRows);
    });
  };

  toTimeSeries: () => any;

  toTable = (): TableDecorator => {
    let resultData: any[] = [];
    let columns: string[] = [];
    columns.push(this.referenceColumn);
    let metricColumns: string[] = [];
    const issueTitles = {};
    const issueTypes = {};
    this.dataHandlerList.forEach((dataHandler, dataHandlerIndex) => {
      const metricColumn = `metric${dataHandlerIndex + 1}`;
      metricColumns.push(metricColumn);
      const rowList = dataHandler.getAggregatedRowList((x: any) => x);
      for (const row of rowList) {
        const refId = row[this.referenceColumn];
        const metricValue = row.value;
        let resultObj = resultData.find(x => x[this.referenceColumn] === refId);
        if (!resultObj) {
          resultObj = this.buildDataObject(this.referenceColumn, this.dataHandlerList.length);
          resultObj[this.referenceColumn] = refId;
          resultData.push(resultObj);
        }
        resultObj[metricColumn] = metricValue;
        if (this.showIssueTitles) {
          issueTitles[refId] = row.rawData[dataHandler.issueTitleColumnIndex];
        }
        if (this.showIssueTypes) {
          issueTypes[refId] = row.rawData[dataHandler.issueTypeColumnIndex];
        }
      }
    });
    if (
      !this.config.referenceCalculation &&
      this.referenceColumn !== 'ref' &&
      !this.config.systemOverall &&
      isFilterValid(this.config.fileInclusionFilter, FilterType.FileInclusionFilter)
    ) {
      resultData = this.applyFileInclusionFilter(resultData);
    }
    if (this.config.calculateMetricRatios) {
      const ratioColumns = this.getRatioColumns(metricColumns);
      resultData = this.calculateRatios(resultData, ratioColumns);
      const newMetricColumns: string[] = [];
      for (const ratioColumn of ratioColumns) {
        newMetricColumns.push(ratioColumn.key);
      }
      metricColumns = newMetricColumns.concat(metricColumns);
    }
    columns = columns.concat(metricColumns);
    if (this.showIssueTypes || this.showIssueTitles) {
      resultData.forEach(x => {
        x.ref =
          (this.showIssueTypes ? '[' + issueTypes[x.ref] + '] ' : '') +
          x.ref +
          (this.showIssueTitles ? ' - ' + issueTitles[x.ref] : '');
      });
    }
    return new TableDecorator(columns, resultData);
  };

  toSingleValue: () => any;

  private buildDataObject = (referenceColumn: string, numberOfMetrics: number): any => {
    const dataObject = {};
    dataObject[referenceColumn] = '';
    for (let index = 1; index <= numberOfMetrics; index++) {
      dataObject[`metric${index}`] = 0;
    }
    return dataObject;
  };

  private getRatioColumns = (metricColumns: string[]): any[] => {
    const ratioColumns: any[] = [];
    for (let i = 0; i < metricColumns.length; i++) {
      for (let j = 0; j < metricColumns.length; j++) {
        if (i === j) {
          continue;
        }
        ratioColumns.push({
          x: metricColumns[i],
          y: metricColumns[j],
          key: `${metricColumns[i]}/${metricColumns[j]}`,
        });
      }
    }
    return ratioColumns;
  };

  private calculateRatios = (dataRows: any[], ratioColumns: any[]): any[] => {
    for (const dataRow of dataRows) {
      for (const ratioColumn of ratioColumns) {
        dataRow[ratioColumn.key] = dataRow[ratioColumn.x] / Math.max(1, Math.round(dataRow[ratioColumn.y] * 2) / 2);
      }
    }
    return dataRows;
  };

  private applyFileInclusionFilter = (dataRows: any[]): any[] => {
    const regexChecker = new RegExp(this.config.fileInclusionFilter);
    return dataRows.filter((x: { path: string }) => x.path === '' || regexChecker.test(x.path));
  };
}
