import { DetangleFilter } from './detangle_filter';

/**
 * This filter removes data, path of which fits the regular expression.
 *
 * @export
 * @class FileExclusionFilter
 * @extends {DetangleFilter}
 */
export class FileExclusionFilter extends DetangleFilter {
  /**
   * Creates regex checker from the filter expression and updates
   * the datahandler with the rows that fits the regular expression.
   *
   * @returns {void}
   */
  process = (): void => {
    if (!this.shouldApplyFilter()) {
      return;
    }
    const regexChecker = new RegExp(this.config.fileExclusionFilter);
    const filteredRows = this.dataListHandler
      .getRowList()
      .filter(item => !regexChecker.test(item.path) || item.path === '');
    this.dataListHandler.updateRowListHard(filteredRows);
  };

  /**
   * This filter is only applied to base calculations, and the data
   * is represented as folders.
   *
   * @returns {boolean}
   */
  shouldApplyFilter = (): boolean => {
    return !this.config.systemOverall && this.isFilterValid(this.config.fileExclusionFilter);
  };
}
