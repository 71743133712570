import { DetangleCalculator } from './detangle_calculator';
import { PeriodicDebtCalculator } from './periodic_debt_calculator';
import { EstimationMethod, WarningLevel } from '../constants';
import { SingleValueDecorator } from '../decorators/single_value_decorator';
import { TableDecorator } from '../decorators/table_decorator';
import { DataListRefType, DataHandler } from '../data_handler';
import { DebtCalculator } from './debt_calculator';
import { nthIndex } from '../utils';

const debtDataIndex = 0;
const issueTitleDataIndex = 1;
const systemEffortDataIndex = 2;
const normalizerDataIndex = 3;
const locDataIndex = (length: number) => length - 1;

export class EstimationCalculator extends DetangleCalculator {
  primaryDataMap: Map<string, any>;
  detangleRatio: number;
  maxRatio: number;
  minRatio: number;
  latestSystemEffort: number;
  previousSystemEffort: number;
  latestAvgSystemEffort: number;
  previousAvgSystemEffort: number;
  systemEffortDataMap: Map<number, any>;
  debtRedLimit: number;
  debtYellowLimit: number;
  debtMin: number;
  debtMax: number;

  process = (): void => {
    const debtDataHandler = this.dataHandlerList[debtDataIndex];
    if (debtDataHandler.refType === DataListRefType.Author) {
      this.debtMax = 30;
      this.debtRedLimit = 20;
      this.debtYellowLimit = 10;
      this.debtMin = 4;
    } else {
      this.debtMax = 15;
      this.debtRedLimit = 10;
      this.debtYellowLimit = 5;
      this.debtMin = 2;
    }
    this.detangleRatio = this.config.detangleRatio;
    this.maxRatio = this.config.debtCriticalPercentage / 100;
    this.minRatio = this.config.debtWarningPercentage / 100;
    this.systemEffortDataMap = this.initSystemEffortDataMap();
    const latestSystemEffortDataItem = this.systemEffortDataMap.get(this.config.latestTimePeriod);
    this.latestSystemEffort = latestSystemEffortDataItem.total;
    this.latestAvgSystemEffort = latestSystemEffortDataItem.avg;
    const previousSystemEffortDataItem = this.systemEffortDataMap.get(this.config.previousTimePeriod);
    this.previousSystemEffort = previousSystemEffortDataItem.total;
    this.previousAvgSystemEffort = previousSystemEffortDataItem.avg;
    this.primaryDataMap = this.initDataMap();
  };

  toTimeSeries: () => any;

  toTable = (): any => {
    let resultData: any[] = [];
    const columns = [
      'path',
      'estimatedTimeLatest',
      'estimatedTimeAvg',
      'estimatedEffort',
      'debtIndex',
      'systemEffortLatest',
      'systemEffortAvg',
    ];
    const latestSystemEffortData = this.systemEffortDataMap.get(this.config.latestTimePeriod);
    this.primaryDataMap.forEach((attr: any, path: string) => {
      const directoryItem = latestSystemEffortData.directories.get(path);
      resultData.push({
        path: path,
        estimatedTimeLatest: attr.estimatedEffortPeriodCount,
        estimatedTimeAvg: attr.estimatedEffortPeriodCountAvg,
        estimatedEffort: Math.round(attr.estimatedEffort),
        debtIndex: attr.debt,
        systemEffortLatest: directoryItem.total,
        systemEffortAvg: directoryItem.avg,
      });
    });
    if (this.config.multiProject) {
      resultData = this.aggregateOneLevelAbove(resultData);
    }
    return new TableDecorator(columns, resultData);
  };

  toSingleValue = () => {
    let latestValue = 0;
    let previousValue = 0;
    let selectedLatestLoc = 0;
    let latestLoc = 0;
    let selectedPrevious = 0;
    let previousLoc = 0;
    switch (this.config.estimationMethod) {
      case EstimationMethod.RawEstimatedEffort:
        this.primaryDataMap.forEach((attr: any) => {
          latestValue += attr.estimatedEffort;
          previousValue += attr.previousEstimatedEffort;
        });
        break;
      case EstimationMethod.AvgEstimatedEffort:
        this.primaryDataMap.forEach((attr: any) => {
          latestValue += attr.estimatedEffortPeriodCountAvg;
          previousValue += attr.previousEstimatedEffortPeriodCountAvg;
        });
        break;
      case EstimationMethod.LatestEstimatedEffort:
        this.primaryDataMap.forEach((attr: any) => {
          latestValue += attr.estimatedEffortPeriodCount;
          previousValue += attr.previousEstimatedEffortPeriodCount;
        });
        break;
      case EstimationMethod.CriticalLocRatio:
        this.primaryDataMap.forEach((attr: any) => {
          latestLoc += attr.loc;
          previousLoc += attr.previousLoc;
          if (attr.warningLevel === WarningLevel.Critical) {
            selectedLatestLoc += attr.loc;
          }
          if (attr.previousWarningLevel === WarningLevel.Critical) {
            selectedPrevious += attr.previousLoc;
          }
        });
        latestValue = selectedLatestLoc / Math.max(1, latestLoc);
        previousValue = selectedPrevious / Math.max(1, previousLoc);
        break;
      case EstimationMethod.WarningLocRatio:
        this.primaryDataMap.forEach((attr: any) => {
          latestLoc += attr.loc;
          previousLoc += attr.previousLoc;
          if (attr.warningLevel === WarningLevel.Warning) {
            selectedLatestLoc += attr.loc;
          }
          if (attr.previousWarningLevel === WarningLevel.Warning) {
            selectedPrevious += attr.previousLoc;
          }
        });
        latestValue = selectedLatestLoc / Math.max(1, latestLoc);
        previousValue = selectedPrevious / Math.max(1, previousLoc);
        break;
    }
    if (this.config.estimationMethod === EstimationMethod.RawEstimatedEffort) {
      latestValue = Math.round(latestValue);
      previousValue = Math.round(previousValue);
    }
    return new SingleValueDecorator(latestValue, previousValue);
  };

  private initDataMap = (): Map<string, any> => {
    const dataMap = new Map<string, any>();
    const debtList = this.getDebt();
    const latestLocList = this.getLocDataForPeriod(this.config.latestTimePeriod);
    const previousLocList = this.getLocDataForPeriod(this.config.previousTimePeriod);
    for (const debtItem of debtList) {
      const path = debtItem.path;
      const attr = {
        debt: debtItem.debt,
        previousDebt: debtItem.previousDebt,
        normalizer: debtItem.normalizer,
        previousNormalizer: debtItem.previousNormalizer,
        loc: 0,
        previousLoc: 0,
        locRatio: 0,
        previousLocRatio: 0,
        estimatedEffort: 0,
        previousEstimatedEffort: 0,
        estimatedEffortPeriodCount: 0,
        previousEstimatedEffortPeriodCount: 0,
        estimatedEffortPeriodCountAvg: 0,
        previousEstimatedEffortPeriodCountAvg: 0,
        warningLevel: WarningLevel.Regular,
        previousWarningLevel: WarningLevel.Regular,
      };
      const locItem = latestLocList.find(x => x.path === path);
      if (!locItem) {
        continue;
      }
      attr.loc = locItem.value;
      const previousLocItem = previousLocList.find(x => x.path === path);
      if (previousLocItem) {
        attr.previousLoc = previousLocItem.value;
      }
      attr.warningLevel = this.getWarningLevelLevel(attr.debt);
      attr.previousWarningLevel = this.getWarningLevelLevel(attr.previousDebt);
      attr.locRatio = this.getLocRatio(attr.debt);
      attr.previousLocRatio = this.getLocRatio(attr.previousDebt);
      attr.estimatedEffort = this.calculateEstimatedEffort(attr.loc, attr.locRatio);
      attr.previousEstimatedEffort = this.calculateEstimatedEffort(attr.previousLoc, attr.previousLocRatio);
      attr.estimatedEffortPeriodCount = attr.estimatedEffort / this.latestSystemEffort || 0;
      attr.previousEstimatedEffortPeriodCount = attr.previousEstimatedEffort / this.previousSystemEffort || 0;
      attr.estimatedEffortPeriodCountAvg = attr.estimatedEffort / this.latestAvgSystemEffort || 0;
      attr.previousEstimatedEffortPeriodCountAvg = attr.previousEstimatedEffort / this.previousAvgSystemEffort || 0;
      dataMap.set(path, attr);
    }
    return dataMap;
  };

  private getDebt = () => {
    const debtHandlerList = [
      this.dataHandlerList[debtDataIndex].clone(),
      this.dataHandlerList[issueTitleDataIndex].clone(),
      this.dataHandlerList[normalizerDataIndex].clone(),
    ];
    const debtCalculator = new PeriodicDebtCalculator(debtHandlerList, this.config);
    debtCalculator.process();
    return debtCalculator.toRawData();
  };

  private getLocDataForPeriod = (currentPeriod: number) => {
    const locDataHandler = this.dataHandlerList[locDataIndex(this.dataHandlerList.length)].clone();
    const acceptedFiles = this.getAcceptedFiles();
    const locFiles = locDataHandler
      .getRowList()
      .filter(item => item.period === currentPeriod && acceptedFiles.includes(item.path));
    locDataHandler.updateRowList(locFiles);
    locDataHandler.setFoldersWithFolderLevel(this.config.folderLevel);
    return locDataHandler.getAggregatedRowListWithTimePeriod(currentPeriod);
  };

  private calculateEstimatedEffort = (loc: number, locRatio: number): number => {
    return this.detangleRatio * loc * locRatio;
  };

  private initSystemEffortDataMap = (): Map<number, any> => {
    const dataMap = new Map<number, any>();
    const systemEffortDataHandler = this.dataHandlerList[systemEffortDataIndex].clone();
    systemEffortDataHandler.setFoldersWithFolderLevel(this.config.folderLevel, false);

    const systemEffortRows = systemEffortDataHandler.getRowList();
    for (const systemEffortRow of systemEffortRows) {
      const tempFolder = systemEffortRow.path;
      const tempPeriod = systemEffortRow.period;
      if (!dataMap.has(tempPeriod)) {
        dataMap.set(tempPeriod, {
          total: 0,
          cumulativeTotal: 0,
          avg: 0,
          directories: new Map<string, any>(),
        });
      }
      const dataMapItem = dataMap.get(tempPeriod);
      if (this.config.folderLevel.includes(systemEffortRow.getFolderLevel())) {
        if (!dataMapItem.directories.has(tempFolder)) {
          dataMapItem.directories.set(tempFolder, {
            total: systemEffortRow.value,
            cumulativeTotal: systemEffortRow.value,
          });
        } else {
          const directoryDataItem = dataMapItem.directories.get(tempFolder);
          directoryDataItem.total += systemEffortRow.value;
          directoryDataItem.cumulativeTotal = directoryDataItem.total;
        }
      }
      dataMapItem.total += systemEffortRow.value;
      dataMapItem.cumulativeTotal = dataMapItem.total;
    }
    const periodList = this.dataHandlerList[systemEffortDataIndex].getPeriodList();
    for (let i = 1; i < periodList.length; i++) {
      const tempPeriod = periodList[i];
      const previousPeriod = periodList[i - 1];
      const tempDataItem = dataMap.get(tempPeriod);
      const previousDataItem = dataMap.get(previousPeriod);
      tempDataItem.cumulativeTotal += previousDataItem.cumulativeTotal;
      previousDataItem.directories.forEach((attr: any, path: string) => {
        if (!tempDataItem.directories.has(path)) {
          tempDataItem.directories.set(path, {
            total: 0,
            cumulativeTotal: attr.cumulativeTotal,
            avg: attr.cumulativeTotal / (i + 1),
          });
        } else {
          const tempDirectoryItem = tempDataItem.directories.get(path);
          tempDirectoryItem.cumulativeTotal += attr.cumulativeTotal;
          tempDirectoryItem.avg = tempDirectoryItem.cumulativeTotal / (i + 1);
        }
      });
      tempDataItem.avg = tempDataItem.cumulativeTotal / (i + 1);
    }
    return dataMap;
  };

  private getAcceptedFiles = (): string[] => {
    const acceptedFiles = [];
    const debtMap = this.getFilesDebt();
    debtMap.forEach((sourceAttr: any, key: string) => {
      acceptedFiles.push(key);
    });
    console.log(acceptedFiles);
    return acceptedFiles;
  };

  private getFilesDebt = (): any => {
    const copyConfig = this.config.clone();
    copyConfig.applyFolderLevel = false;
    const debtDataHandler: DataHandler = this.dataHandlerList[debtDataIndex].clone();
    debtDataHandler.updateRowList(
      debtDataHandler.getAggregatedRowListWithTimePeriod(this.config.latestTimePeriod, this.config.periodsToConsider)
    );
    const debtHandlerList = [debtDataHandler, this.dataHandlerList[issueTitleDataIndex]];
    const debtCalculator = new DebtCalculator(debtHandlerList, copyConfig);
    debtCalculator.process();
    return debtCalculator.toRawData();
  };

  private aggregateOneLevelAbove = (tableArray: any[]): any[] => {
    const newTableArray: any[] = [];

    for (const tableRow of tableArray) {
      const path = tableRow.path;
      const currentfolderLevel = (path.match(/\//g) || []).length;
      const upperFolderPath = path.substring(0, nthIndex(path, '/', currentfolderLevel - 1) + 1);
      let newTableRow = newTableArray.find(x => x.path === upperFolderPath);

      if (!newTableRow) {
        newTableRow = {
          path: upperFolderPath,
          estimatedTimeLatest: 0,
          estimatedTimeAvg: 0,
          estimatedEffort: 0,
          debtIndex: 0,
          systemEffortLatest: 0,
          systemEffortAvg: 0,
        };
        newTableArray.push(newTableRow);
      }
      newTableRow.estimatedTimeLatest += tableRow.estimatedTimeLatest;
      newTableRow.estimatedTimeAvg += tableRow.estimatedTimeAvg;
      newTableRow.estimatedEffort += tableRow.estimatedEffort;
      newTableRow.debtIndex += tableRow.debtIndex;
      newTableRow.systemEffortLatest += tableRow.systemEffortLatest;
      newTableRow.systemEffortAvg += tableRow.systemEffortAvg;
    }
    return newTableArray;
  };

  //
  // Weight Calculation
  //
  // private getLatestSystemEffort = (): number => {
  //   const systemEffortDataHandler = this.dataHandlerList[systemEffortDataIndex].clone();
  //   const systemRowList = systemEffortDataHandler.getAggregatedRowListWithTimePeriod(
  //     this.config.latestTimePeriod,
  //     this.config.periodsToConsider
  //   );
  //   const cumulativeRow = getAsRevisionData(systemRowList);
  //   const totalSystemEffort = cumulativeRow[0].value;
  //   return totalSystemEffort;
  // }
  //
  //
  // private getDirectoryWeights = (): any => {
  //   const systemEffortDataHandler = this.dataHandlerList[systemEffortDataIndex].clone();
  //   const systemRowList = systemEffortDataHandler.getAggregatedRowListWithTimePeriod(
  //     this.config.latestTimePeriod,
  //     this.config.periodsToConsider
  //   );
  //   const totalSystemEffort = this.getLatestSystemEffort();
  //   const weightHash = {};
  //   const buildWeightObj = (folderLevel: number, referenceFile: DataRowElement) => {
  //     const currentFolderLevel = referenceFile.getFolderLevel();
  //     let weightFolder = '';
  //     const minFolderLevel = Math.min(...this.config.folderLevel);
  //     if (currentFolderLevel >= minFolderLevel) {
  //       weightFolder = referenceFile.getPathWithLevel(minFolderLevel);
  //     }
  //     return {
  //       folderEffort: 0,
  //       files: [],
  //       weight: 0,
  //       folderLevel: folderLevel,
  //       weightFolder: weightFolder,
  //     };
  //   };
  //   systemRowList.forEach(x => {
  //     let currentFolderLevel = x.getFolderLevel();
  //     while (currentFolderLevel > 0) {
  //       const currentDirectory = x.getPathWithLevel(currentFolderLevel);
  //       if (!weightHash[currentDirectory]) {
  //         weightHash[currentDirectory] = buildWeightObj(currentFolderLevel, x);
  //       }
  //       const tempDirectoryWeightHash = weightHash[currentDirectory];
  //       tempDirectoryWeightHash.files.push(x.path);
  //       tempDirectoryWeightHash.folderEffort += x.value;
  //       currentFolderLevel -= 1;
  //     }
  //   });
  //   if (calculationOption === 2) {
  //     for (const tempDirectory in weightHash) {
  //       const tempDirectoryWeightHash = weightHash[tempDirectory];
  //       tempDirectoryWeightHash.weight = tempDirectoryWeightHash.folderEffort / totalSystemEffort;
  //     }
  //   }
  //   if (calculationOption === 3) {
  //     for (const tempDirectory in weightHash) {
  //       const tempDirectoryWeightHash = weightHash[tempDirectory];
  //       const weightFolderHash = weightHash[tempDirectoryWeightHash.weightFolder];
  //       if (weightFolderHash) {
  //         tempDirectoryWeightHash.weight = tempDirectoryWeightHash.folderEffort / weightFolderHash.folderEffort;
  //       }
  //     }
  //   }
  //   return weightHash;
  // };

  private getLocRatio = (debt: number): number => {
    if (debt <= this.debtMin) {
      return 0;
    }
    if (debt >= this.debtMax) {
      return this.maxRatio;
    }
    return ((this.maxRatio - this.minRatio) / (this.debtMax - this.debtMin)) * (debt - this.debtMin) + this.minRatio;
  };

  private getWarningLevelLevel = (debt: number): WarningLevel => {
    if (debt >= this.debtRedLimit) {
      return WarningLevel.Critical;
    }
    if (debt >= this.debtYellowLimit) {
      return WarningLevel.Warning;
    }
    return WarningLevel.Regular;
  };
}
