const PathKey = 'path';

/**
 * Decorator is responsible for providing data according to the needs
 * of Table Plugin.
 *
 * @export
 * @class TableDecorator
 */
export class TableDecorator {
  private columns: string[];
  private data: any[];
  private tableObj: any;

  /**
   * Creates an instance of TableDecorator.
   *
   * @param {string[]} columns
   * @param {any[]} dataList
   * @memberof TableDecorator
   */
  constructor(columns: string[], dataList: any[]) {
    this.data = dataList;
    this.columns = columns;
    this.tableObj = this.initTableObj();
  }

  /**
   * Initializes the table object.
   *
   * @returns {*}
   */
  private initTableObj = (): any => {
    const tableObj = {
      type: 'table',
      columnMap: {},
      columns: [],
      rows: [],
    };
    const isTherePathKey = this.columns.includes(PathKey);
    if (isTherePathKey) {
      this.columns.splice(0, 0, this.columns.splice(this.columns.indexOf(PathKey), 1)[0]);
    }
    for (let i = 0; i < this.columns.length; i++) {
      const tempColumnName = this.columns[i];
      const tempColumnMapObj = this.getColumnMapObject(tempColumnName);
      tableObj.columnMap[tempColumnName] = tempColumnMapObj;
      tableObj.columns.push(tempColumnMapObj);
    }
    for (let i = 0; i < this.data.length; i++) {
      const tempData = this.data[i];
      const tempRowAttrArray = [];
      for (let j = 0; j < this.columns.length; j++) {
        const tempColumnName = this.columns[j];
        tempRowAttrArray.push(tempData[tempColumnName]);
      }

      tableObj.rows.push(tempRowAttrArray);
    }
    return tableObj;
  };

  /**
   * Gets the table object.
   *
   * @returns {*}
   */
  getTable = (): any => {
    return this.tableObj;
  };

  /**
   * Gets the table object as if it is returned from elasticsearch.
   *
   * @returns {any[]}
   */
  getAsQueryResult = (): any[] => {
    return [this.tableObj];
  };

  /**
   * Gets the column map object.
   *
   *  It sets the path column as filterable.
   *
   * @param {string} columnName
   * @returns {*}
   */
  private getColumnMapObject = (columnName: string): any => {
    const columnMapObj = { text: columnName };
    if (columnName === PathKey) {
      columnMapObj['filterable'] = true;
    }
    return columnMapObj;
  };
}
